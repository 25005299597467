<app-header-one></app-header-one>

<div class="home-slides-two owl-carousel owl-theme">
    <div class="main-banner banner-bg4">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content black-text">
                                <span class="sub-title">Stay Safe &</span>
                                <h1>Be Informed About Covid-19</h1>
                                <div class="btn-box">
                                    <a routerLink="/about-one" class="default-btn"><i class="flaticon-open-book"></i> Learn More</a>
                                    <a href="https://www.youtube.com/watch?v=8c_UJwLq8PI" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg5">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content black-text">
                                <span class="sub-title">Stay Safe &</span>
                                <h1>Be Informed About Covid-19</h1>
                                <div class="btn-box">
                                    <a routerLink="/about-one" class="default-btn"><i class="flaticon-open-book"></i> Learn More</a>
                                    <a href="https://www.youtube.com/watch?v=8c_UJwLq8PI" class="popup-youtube optional-btn"><i class="flaticon-play-button"></i> Watch Video</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="outbreak-area ptb-100">
    <div class="container">
        <div class="outbreak-content">
            <div class="outbreak-box-list">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon5.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="196">00</h3>
                            <p>Total Country</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon6.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="375498">00</h3>
                            <p>Confirmed Cases</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon7.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="16362">00</h3>
                            <p>Deaths</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-outbreak-box">
                            <div class="icon">
                                <img src="assets/img/icon8.png" alt="icon">
                            </div>
                            <h3 class="odometer" data-count="109102">00</h3>
                            <p>Recovered</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src="assets/img/banner-map.png" alt="image">

            <div class="table-responsive">
                <table id="outbreakTable" class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Country,<br>Other</th>
                            <th>Total<br>Cases</th>
                            <th>New<br>Cases</th>
                            <th>Total<br>Deaths</th>
                            <th>New<br>Deaths</th>
                            <th>Total<br>Recovered</th>
                            <th>Active<br>Cases</th>
                            <th>Serious,<br>Critical</th>
                            <th>Tot&nbsp;Cases/<br>1M pop</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Total</th>
                            <th>375,498</th>
                            <th class="new_case">3,267</th>
                            <th class="new_death">13,068</th>
                            <th>61</th>
                            <th>95,828</th>
                            <th>199,361</th>
                            <td>9,943</td>
                            <td>39.5</td>
                        </tr>
                    </tfoot>
                    <tbody>
                        <tr>
                            <td><a href="#">China</a></td>
                            <td>81,218</td>
                            <td class="new_case">+47</td>
                            <td>3,281</td>
                            <td class="new_death">+4</td>
                            <td>73,650</td>
                            <td>4,287</td>
                            <td>1,399</td>
                            <td>56</td>
                        </tr>
                        <tr>
                            <td><a href="#">Italy</a></td>
                            <td>69,176</td>
                            <td></td>
                            <td>6,820</td>
                            <td></td>
                            <td>8,326</td>
                            <td>54,030</td>
                            <td>3,393</td>
                            <td>1,144</td>
                        </tr>
                        <tr>
                            <td><a href="#">USA</a></td>
                            <td>54,935</td>
                            <td class="new_case">+54</td>
                            <td>784</td>
                            <td class="new_death">+4</td>
                            <td>379</td>
                            <td>53,772</td>
                            <td>1,175</td>
                            <td>166</td>
                        </tr>
                        <tr>
                            <td><a href="#">Spain</a></td>
                            <td>42,058</td>
                            <td></td>
                            <td>2,991</td>
                            <td></td>
                            <td>3,794</td>
                            <td>35,273</td>
                            <td>2,636</td>
                            <td>900</td>
                        </tr>
                        <tr>
                            <td><a href="#">Germany</a></td>
                            <td>32,991</td>
                            <td></td>
                            <td>159</td>
                            <td></td>
                            <td>3,290</td>
                            <td>29,542</td>
                            <td>23</td>
                            <td>394</td>
                        </tr>
                        <tr>
                            <td><a href="#">Iran</a></td>
                            <td>24,811</td>
                            <td></td>
                            <td>1,934</td>
                            <td></td>
                            <td>8,913</td>
                            <td>13,964</td>
                            <td></td>
                            <td>295</td>
                        </tr>
                        <tr>
                            <td><a href="#">France</a></td>
                            <td>22,304</td>
                            <td></td>
                            <td>1,100</td>
                            <td></td>
                            <td>3,281</td>
                            <td>17,923</td>
                            <td>2,516</td>
                            <td>342</td>
                        </tr>
                        <tr>
                            <td><a href="#">Switzerland</a></td>
                            <td>9,991</td>
                            <td class="new_case">+114</td>
                            <td>133</td>
                            <td class="new_death">+11</td>
                            <td>131</td>
                            <td>9,727</td>
                            <td>141</td>
                            <td>1,154</td>
                        </tr>
                        <tr>
                            <td><a href="#">S. Korea</a></td>
                            <td>9,137</td>
                            <td class="new_case">+100</td>
                            <td>126</td>
                            <td class="new_death">+6</td>
                            <td>3,730</td>
                            <td>5,281</td>
                            <td>59</td>
                            <td>178</td>
                        </tr>
                        <tr>
                            <td><a href="#">UK</a></td>
                            <td>8,077</td>
                            <td></td>
                            <td>422</td>
                            <td></td>
                            <td>135</td>
                            <td>7,520</td>
                            <td>20</td>
                            <td>119</td>
                        </tr>
                        <tr>
                            <td><a href="#">Netherlands</a></td>
                            <td>5,560</td>
                            <td></td>
                            <td>276</td>
                            <td></td>
                            <td>2</td>
                            <td>5,282</td>
                            <td>435</td>
                            <td>324</td>
                        </tr>
                        <tr>
                            <td><a href="#">Austria</a></td>
                            <td>5,394</td>
                            <td class="new_case">+111</td>
                            <td>30</td>
                            <td class="new_death">+2</td>
                            <td>9</td>
                            <td>5,355</td>
                            <td>26</td>
                            <td>599</td>
                        </tr>
                        <tr>
                            <td><a href="#">Belgium</a></td>
                            <td>4,269</td>
                            <td></td>
                            <td>122</td>
                            <td></td>
                            <td>461</td>
                            <td>3,686</td>
                            <td>381</td>
                            <td>368</td>
                        </tr>
                        <tr>
                            <td><a href="#">Norway</a></td>
                            <td>2,868</td>
                            <td class="new_case">+2</td>
                            <td>13</td>
                            <td class="new_death">+1</td>
                            <td>6</td>
                            <td>2,849</td>
                            <td>44</td>
                            <td>529</td>
                        </tr>
                        <tr>
                            <td><a href="#">Canada</a></td>
                            <td>2,792</td>
                            <td></td>
                            <td>26</td>
                            <td></td>
                            <td>112</td>
                            <td>2,654</td>
                            <td>1</td>
                            <td>74</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<section class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                    <img src="assets/img/about-img2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Covid-19</span>
                    <h2>About Coronavirus Disease</h2>
                    <p>In 2019, the Centers for Disease Control and Prevention (CDC) started monitoring the outbreak of a new coronavirus, SARS-CoV-2, which causes the respiratory illness now known as COVID-19. Authorities first identified the virus in Wuhan, China.</p>
                    <p>More than 78,191 people have contracted the virus in China. Health authorities have identified many other people with COVID-19 around the world, including in the United States. On January 31, 2020, the virus passed from one person to another in the U.S.</p>
                    <blockquote>
                        <p>The World Health Organization (WHO) have declared a public health emergency relating to COVID-19.</p>
                    </blockquote>
                    <p>Since then, this strain has been diagnosed in several U.S. residents. The CDC have advised that it is likely to spread to more people. COVID-19 has started causing disruption in at least 25 other countries.</p>

                    <a routerLink="/about-one" class="default-btn"><i class="flaticon-open-book"></i> Know More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cta-area">
    <div class="container">
        <div class="cta-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="row align-items-center">
                        <div class="col-lg-5 col-md-5">
                            <img src="assets/img/man.png" alt="image">
                        </div>

                        <div class="col-lg-7 col-md-7">
                            <h3>We Operate 24h a day - Every Day!</h3>
                            <p>Call us if you have any problems.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="cta-btn">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="how-to-spread-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Spreads Virus</span>
            <h2>How Covid-19 Spreads</h2>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/spread-virus/img4.jpg" class="main-image" alt="image">

                    <div class="shape">
                        <img src="assets/img/circle-shape.png" alt="image">
                        <img src="assets/img/circle-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon2.png" alt="image">
                    </div>
                    <h3>Close Contact With An Infected Person</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon3.png" alt="image">
                    </div>
                    <h3>Droplets Spread When Infected Person Coughs or Sneezes</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>

            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/spread-virus/img5.jpg" class="main-image" alt="image">

                    <div class="shape">
                        <img src="assets/img/circle-shape.png" alt="image">
                        <img src="assets/img/circle-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/spread-virus/img6.jpg" class="main-image" alt="image">

                    <div class="shape">
                        <img src="assets/img/circle-shape.png" alt="image">
                        <img src="assets/img/circle-shape2.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon4.png" alt="image">
                    </div>
                    <h3>Contact With Contaminated Surfaces or Objects</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/symptoms-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="prevention-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Prevention</span>
            <h2>How to Protect Yourself</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Should Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon1.png" alt="image">
                            </div>
                            <h3>Wash Your Hand For 20 Sec</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon2.png" alt="image">
                            </div>
                            <h3>Wear Mask All The Time</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon3.png" alt="image">
                            </div>
                            <h3>Avoid Contact With Animals</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon4.png" alt="image">
                            </div>
                            <h3>Always Cover Your Sneeze</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="prevention-content">
                    <div class="title">
                        <h3>Things You Shouldn’t Do</h3>
                    </div>

                    <div class="content-list">
                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon5.png" alt="image">
                            </div>
                            <h3>Avoid Crowded Places</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon6.png" alt="image">
                            </div>
                            <h3>Don't Handshake</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon7.png" alt="image">
                            </div>
                            <h3>Don't Touch Your Face</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>

                        <div class="content">
                            <div class="icon">
                                <img src="assets/img/prevention-icon8.png" alt="image">
                            </div>
                            <h3>Avoid Travel</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="prevention-shape1"><img src="assets/img/prevention-shape1.png" alt="image"></div>
    <div class="prevention-shape2"><img src="assets/img/prevention-shape2.png" alt="image"></div>
    <div class="prevention-shape3"><img src="assets/img/prevention-shape3.png" alt="image"></div>
    <div class="prevention-shape4"><img src="assets/img/prevention-shape4.png" alt="image"></div>
</section>

<section class="faq-section-two bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Faq's</span>
            <h2>Frequently Asked & Questions</h2>
        </div>

        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-book-reader'></i><span>COVID-19 Basics</span></a></li>
                
                <li><a href="#"><i class='bx bx-command'></i><span>Prevention</span></a></li>

                <li><a href="#"><i class='bx bx-user-check'></i><span>Self Quarantine</span></a></li>

                <li><a href="#"><i class='bx bx-user-pin'></i><span>For Caregivers</span></a></li>

                <li><a href="#"><i class='bx bx-info-square'></i><span>Terms to Know</span></a></li>
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExTwo" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSix">
                                <a data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSix" class="collapse show" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSeven">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEight">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingNine">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExThree" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEleven">
                                <a data-toggle="collapse" data-parent="#accordionExThree" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEleven" class="collapse show" role="tabpanel" aria-labelledby="headingEleven" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwelve">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwelve" class="collapse" role="tabpanel" aria-labelledby="headingTwelve" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThirteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThirteen" class="collapse" role="tabpanel" aria-labelledby="headingThirteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFourteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFourteen" class="collapse" role="tabpanel" aria-labelledby="headingFourteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFifteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFifteen" class="collapse" role="tabpanel" aria-labelledby="headingFifteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExFour" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSixteen">
                                <a data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSixteen" class="collapse show" role="tabpanel" aria-labelledby="headingSixteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSeventeen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSeventeen" class="collapse" role="tabpanel" aria-labelledby="headingSeventeen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEighteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEighteen" class="collapse" role="tabpanel" aria-labelledby="headingEighteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingNineteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseNineteen" class="collapse" role="tabpanel" aria-labelledby="headingNineteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwenty">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwenty" class="collapse" role="tabpanel" aria-labelledby="headingTwenty" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExFive" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyOne">
                                <a data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyOne" class="collapse show" role="tabpanel" aria-labelledby="headingTwentyOne" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwentyTwo" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyThree" class="collapse" role="tabpanel" aria-labelledby="headingTwentyThree" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyFour" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFour" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyFive" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFive" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Doctors</span>
            <h2>Meet Specialist Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="emergency-contact-area">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/doctors" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Worldwide Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Covid</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Adjust</span>
                        <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Coronavirus</span>
                        <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>