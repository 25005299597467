<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Conseiller en ligne</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Tchat</li>
            </ul>
        </div>
    </div>
</section>
 
    <div class="container">  
        <h1>Cliquer sur l'icone à en bas à gauche pour commencer le chat.</h1> 
        <iframe frameborder="0" src="https://api.destiny-innovation.com/tchat/tchatuy1.html"    style="height: 600px;width: 100%;"></iframe>
        <br>
    </div>
  