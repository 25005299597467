import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operators/map';
import { ServiceService } from '../services/service.service';
//import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Observable, of } from "rxjs";

//import { HttpClient } from '@angular/common/http';
//import { map } from "rxjs/operators";


import { NgForm } from '@angular/forms'; 

import { HttpClient } from '@angular/common/http';

//Inventaire d'Intérêts Professionnels
@Component({
  selector: 'app-irmr',
  templateUrl: './irmr.component.html',
  styleUrls: ['./irmr.component.css']
})
export class IrmrComponent implements OnInit {

  panelOpenState = false;


  lancerTest = false;
  firstDiv = true;
  lastDiv = false;
  contentNext = "Suivant"
  isErrorResultTest: Boolean;
  tabBooleanBloc = new Array(9);
  demostate: string = "active";
  demostatebool: boolean=false;
  _titleHelp: string = "";
  _contentHelp: string = "";
  mycode:string=""
  valueProgress = Math.trunc(100 / 9)-2

  verifResult=false;

  tabGrp1MetierChoix = new Array(12);
  tabGrp2MetierChoix = new Array(12);
  tabGrp3MetierChoix = new Array(12);
  tabGrp4MetierChoix = new Array(12);
  tabGrp5MetierChoix = new Array(12);
  tabGrp6MetierChoix = new Array(12);
  tabGrp7MetierChoix = new Array(12);
  tabGrp8MetierChoix = new Array(12);
  tabGrp9MetierChoix = new Array(12);

  tabTotal = new Array(12);
  tabRang = new Array(12);
  tabEtalonnage = new Array(12);
  tabTotalTypeH = new Array(6);

  metierCle1 = new FormControl('');
  metierCle2 = new FormControl('');
  metierCle3 = new FormControl('');

  job1 = false
  job2 = false
  job3 = false

  private testResult: string = "";
  nameuser: string = "";
  testCode: string = "";
codevv;
  TypeHollandR: number;
  TypeHollandI: number;
  TypeHollandA: number;
  TypeHollandS: number;
  TypeHollandE: number;
  TypeHollandC: number;

  resultClasseCat: string = "";
  testLanguage: string = "";
  K3: string = "test123";
  isEndTest: Boolean;
  visa: Boolean;
  isresulTestIRMR: Boolean;
  currentIndex = 0;
  tmpBoolDifElt: Boolean;

  tokenDoublon: string = "";
  nomPage: string = "";
  numPage: number = 0;

  lienbase: string = "";

  cpteur=0;
  MsgErreurRangPart1: string = "";
  MsgErreurRangPart2: string = "";
  MsgConfirmTest: string = "";
  QuestConfirmTest: string = "";
  Non: string = "";
  Oui: string = "";
  _CLY: string;
  compteurGrpeM = 1

  public url1: any;

  grp1MetierChoix = ['Cultivateur', 'Ingénieur', 'Comptable', 'Chercheur Scientifique', 'Directeur commercial', 'Artiste Peintre',
    'Journaliste', 'Pianiste de Concert', 'Instituteur', 'Directeur Administratif', 'Tisserand', 'Médecin'];

  grp2MetierChoix = ['Ingénieur Mécanicien', 'Statisticien', 'Chimiste', 'Animateur de radio', 'Déssinateur Publicitaire', 'Romancier',
    "Chef d'Orchestre", 'Psychologue Scolaire', 'Secrétaire', 'Menuisier', 'Chirurgien', "Professeur d'Education Physique"];

  grp3MetierChoix = ['Agent de Change', 'Météorologue', 'Vendeur', 'Architecte', 'Auteur Dramatique', 'Compositeur de musique',
    'Professeur', 'Opérateur sur traitement de Texte', 'Coiffeur', 'Vétérinaire', 'Géomètre', 'Technicien'];

  grp4MetierChoix = ['Biologiste', 'Publicitaire', "Illustrateur d'Ouvrages", 'Historien', 'Critique Musicale', 'Travailleur Social',
    'Employé de Banque', 'Relieur', 'Pharmacien', 'Explorateur', 'Outilleur', 'Expert-comptable'];

  grp5MetierChoix = ['Enquêteur', 'Créateur de Bijoux', 'Bibliothécaire', 'Professeur de musique', 'Educateur', 'Archiviste',
    'Maçon', 'Dentiste', 'Entraîneur Sportif', 'Ingénieur électricien', 'Inspecteur des Impôts', 'Physicien'];

  grp6MetierChoix = ['Photographe', 'Rédacteur dans un périodique', 'Violomiste', 'Gestionnaire du personnel', "Employé de bureau d'Assurance", 'Horlogien',
    'Opticien', 'Horticulteur', 'Technicien des télécommunications', 'Professeur de Mathématiques', 'Botaniste', 'Représentant de Commerce'];

  grp7MetierChoix = ['Critique Littéraire', 'Disquaire', 'Animateur de Centre Aéré', 'Employé de Bureau', 'Plombier', 'Radiologue',
    'Jardinier', 'Mécanicien Garagiste', 'Programmeur', 'Astronome', 'Commissaire Priseur', 'Créateur de Décors de Théâtre'];

  grp8MetierChoix = ['Guitariste', "Conseiller d'Orientation", 'Employé des Postes', 'Réparateur de bijoux', 'Kinésithérapeute', 'Guide Touristique',
    'Technicien Hi fi/Vidéo', 'Commissaire aux Comptes', 'Géologue', 'Agent de Télé-Marketing', 'Décorateur de Vitrines', 'Scénariste'];

  grp9MetierChoix = ['Prêtre', 'Commis de Bureau', 'Boulangier', 'Infirmier', 'Maraîcher', 'Ajusteur',
    'Caissier de Banque', 'Technicien de laboratoire', 'Vendeur Démonstrateur', 'Couturier', 'Poète', "Vendeur d'instrument de Musique"];


  isDisabled = true

  constructor(public dataSave: ServiceService, private http: Http,private http1: HttpClient) {

  }
  /*recupinfopharma() {
    this.url1=this.param.lien+'recup_infoPharma.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }*/

  ngOnInit(): void { 
    this.lienbase = this.dataSave.lien;
   }

  recup(l: String, p: String) {


    
    this.url1=this.lienbase+'Destiny_add_resultirmr.php?irmrID='+p+'&resultClasseCat='+p+'&TypeHollandR='+p+'&TypeHollandI='+p+'&TypeHollandA='+p
    +'&TypeHollandS='+p+'&TypeHollandE='+p+'&TypeHollandC='+p+'&choix1metieruser='+p+'&choix2metieruser='+p+'&choix3metieruser='+p
    +'&datetest='+p+'&userID='+p+'&activated='+p+'&deleted='+p+'&updated='+p

    /*
    this.url1 = this.dataSave.lien + 'Destiny_add_generationdoc.php?genID=' + p + '&testRef=' + p + '&dategen=' + p + '&userID=' + p + '&activated=' + p + '&deleted=' + p + '&updated=' + p

   
    this.url1=this.param.lien+'Destiny_add_resultmbti.php?mbtiID='+p+'&typePesonnality='+p+'&subsum1TabRepT='+p+'&subsum2TabRepT='+p
    +'&subsum3TabRepT='+p+'&subsum4TabRepT='+p+'&datetest='+p+'&userID='+p+'&activated='+p+'&deleted='+p+'&updated='+p
    this.url1=this.param.lien+'Destiny_add_userdestiny.php?userID='+p+'&nomUser='+p+'&prenomUser='+p+'&dateNaisUser='+p+'&sexe='+p
    +'&adressUser='+p+'&situationmatriUser='+p+'&nationalite='+p+'&phoneNumber='+p+'&email='+p+'&enabled='+p+'&login='+p
    +'&password='+p+'&profilPicture='+p+'&decriptUser='+p+'&typeUser='+p+'&commentuser='+p+'&activated='+p+'&deleted='+p+'&updated='+p
 
*/


/*
    //this.url1=this.dataSave.lien+'connexion.php?log='+l+'&pass='+p
    return this.http
      .get(this.url1)
      .map((data: Response) => data.json())
*/
    
  }





  initGestionTest() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    
    /*
        alert(this.isDisabled)
        setTimeout(function () {
          alert("Sup!");
    
        }, 5000
    
        );//wait 2 seconds
        this.isDisabled = !this.isDisabled,
        alert(this.isDisabled)
    */


    this.resultClasseCat = "";



    this.lancerTest = true;
    this.isEndTest = false;
    this.isresulTestIRMR = false;

    this.nameuser = '.........';
    this.testCode = 'MBTI02S1';

  }

  drop(event: CdkDragDrop<string[]>, cible) {

    this.cpteur +=1 
    moveItemInArray(cible, event.previousIndex, event.currentIndex);
    //console.log(cible[0])

    if(this.cpteur >= 5){
      this.isDisabled = false

    }
  }



  updateWeight(cible) {

    if (cible == 1) {
      this.tabGrp1MetierChoix[0] = this.grp1MetierChoix.indexOf("Cultivateur");
      this.tabGrp1MetierChoix[1] = this.grp1MetierChoix.indexOf("Ingénieur");
      this.tabGrp1MetierChoix[2] = this.grp1MetierChoix.indexOf("Comptable");
      this.tabGrp1MetierChoix[3] = this.grp1MetierChoix.indexOf("Chercheur Scientifique");
      this.tabGrp1MetierChoix[4] = this.grp1MetierChoix.indexOf("Directeur commercial");
      this.tabGrp1MetierChoix[5] = this.grp1MetierChoix.indexOf("Artiste Peintre");
      this.tabGrp1MetierChoix[6] = this.grp1MetierChoix.indexOf("Journaliste");
      this.tabGrp1MetierChoix[7] = this.grp1MetierChoix.indexOf("Pianiste de Concert");
      this.tabGrp1MetierChoix[8] = this.grp1MetierChoix.indexOf("Instituteur");
      this.tabGrp1MetierChoix[9] = this.grp1MetierChoix.indexOf("Directeur Administratif");
      this.tabGrp1MetierChoix[10] = this.grp1MetierChoix.indexOf("Tisserand");
      this.tabGrp1MetierChoix[11] = this.grp1MetierChoix.indexOf("Médecin");
      //console.log(this.tabGrp1MetierChoix[2])
    }
    if (cible == 2) {
      this.tabGrp2MetierChoix[0] = this.grp2MetierChoix.indexOf("Ingénieur Mécanicien");
      this.tabGrp2MetierChoix[1] = this.grp2MetierChoix.indexOf("Statisticien");
      this.tabGrp2MetierChoix[2] = this.grp2MetierChoix.indexOf("Chimiste");
      this.tabGrp2MetierChoix[3] = this.grp2MetierChoix.indexOf("Animateur de radio");
      this.tabGrp2MetierChoix[4] = this.grp2MetierChoix.indexOf("Déssinateur Publicitaire");
      this.tabGrp2MetierChoix[5] = this.grp2MetierChoix.indexOf("Romancier");
      this.tabGrp2MetierChoix[6] = this.grp2MetierChoix.indexOf("Chef d'Orchestre");
      this.tabGrp2MetierChoix[7] = this.grp2MetierChoix.indexOf("Psychologue Scolaire");
      this.tabGrp2MetierChoix[8] = this.grp2MetierChoix.indexOf("Secrétaire");
      this.tabGrp2MetierChoix[9] = this.grp2MetierChoix.indexOf("Menuisier");
      this.tabGrp2MetierChoix[10] = this.grp2MetierChoix.indexOf("Chirurgien");
      this.tabGrp2MetierChoix[11] = this.grp2MetierChoix.indexOf("Professeur d'Education Physique");
      //console.log(this.tabGrp2MetierChoix[2])

    }

    if (cible == 3) {
      this.tabGrp3MetierChoix[0] = this.grp3MetierChoix.indexOf("Agent de Change");
      this.tabGrp3MetierChoix[1] = this.grp3MetierChoix.indexOf("Météorologue");
      this.tabGrp3MetierChoix[2] = this.grp3MetierChoix.indexOf("Vendeur");
      this.tabGrp3MetierChoix[3] = this.grp3MetierChoix.indexOf("Architecte");
      this.tabGrp3MetierChoix[4] = this.grp3MetierChoix.indexOf("Auteur Dramatique");
      this.tabGrp3MetierChoix[5] = this.grp3MetierChoix.indexOf("Compositeur de musique");
      this.tabGrp3MetierChoix[6] = this.grp3MetierChoix.indexOf("Professeur");
      this.tabGrp3MetierChoix[7] = this.grp3MetierChoix.indexOf("Opérateur sur traitement de Texte");
      this.tabGrp3MetierChoix[8] = this.grp3MetierChoix.indexOf("Coiffeur");
      this.tabGrp3MetierChoix[9] = this.grp3MetierChoix.indexOf("Vétérinaire");
      this.tabGrp3MetierChoix[10] = this.grp3MetierChoix.indexOf("Géomètre");
      this.tabGrp3MetierChoix[11] = this.grp3MetierChoix.indexOf("Technicien");
      //console.log(this.tabGrp3MetierChoix[3])

    }

    if (cible == 4) {
      this.tabGrp4MetierChoix[0] = this.grp4MetierChoix.indexOf("Biologiste");
      this.tabGrp4MetierChoix[1] = this.grp4MetierChoix.indexOf("Publicitaire");
      this.tabGrp4MetierChoix[2] = this.grp4MetierChoix.indexOf("Illustrateur d'Ouvrages");
      this.tabGrp4MetierChoix[3] = this.grp4MetierChoix.indexOf("Historien");
      this.tabGrp4MetierChoix[4] = this.grp4MetierChoix.indexOf("Critique Musicale");
      this.tabGrp4MetierChoix[5] = this.grp4MetierChoix.indexOf("Travailleur Social");
      this.tabGrp4MetierChoix[6] = this.grp4MetierChoix.indexOf("Employé de Banque");
      this.tabGrp4MetierChoix[7] = this.grp4MetierChoix.indexOf("Relieur");
      this.tabGrp4MetierChoix[8] = this.grp4MetierChoix.indexOf("Pharmacien");
      this.tabGrp4MetierChoix[9] = this.grp4MetierChoix.indexOf("Explorateur");
      this.tabGrp4MetierChoix[10] = this.grp4MetierChoix.indexOf("Outilleur");
      this.tabGrp4MetierChoix[11] = this.grp4MetierChoix.indexOf("Expert-comptable");
      //console.log(this.tabGrp4MetierChoix[4])

    }

    if (cible == 5) {
      this.tabGrp5MetierChoix[0] = this.grp5MetierChoix.indexOf("Enquêteur");
      this.tabGrp5MetierChoix[1] = this.grp5MetierChoix.indexOf("Créateur de Bijoux");
      this.tabGrp5MetierChoix[2] = this.grp5MetierChoix.indexOf("Bibliothécaire");
      this.tabGrp5MetierChoix[3] = this.grp5MetierChoix.indexOf("Professeur de musique");
      this.tabGrp5MetierChoix[4] = this.grp5MetierChoix.indexOf("Educateur");
      this.tabGrp5MetierChoix[5] = this.grp5MetierChoix.indexOf("Archiviste");
      this.tabGrp5MetierChoix[6] = this.grp5MetierChoix.indexOf("Maçon");
      this.tabGrp5MetierChoix[7] = this.grp5MetierChoix.indexOf("Dentiste");
      this.tabGrp5MetierChoix[8] = this.grp5MetierChoix.indexOf("Entraîneur Sportif");
      this.tabGrp5MetierChoix[9] = this.grp5MetierChoix.indexOf("Ingénieur électricien");
      this.tabGrp5MetierChoix[10] = this.grp5MetierChoix.indexOf("Inspecteur des Impôts");
      this.tabGrp5MetierChoix[11] = this.grp5MetierChoix.indexOf("Physicien");
      //console.log(this.tabGrp5MetierChoix[5])

    }

    if (cible == 6) {
      this.tabGrp6MetierChoix[0] = this.grp6MetierChoix.indexOf("Photographe");
      this.tabGrp6MetierChoix[1] = this.grp6MetierChoix.indexOf("Rédacteur dans un périodique");
      this.tabGrp6MetierChoix[2] = this.grp6MetierChoix.indexOf("Violomiste");
      this.tabGrp6MetierChoix[3] = this.grp6MetierChoix.indexOf("Gestionnaire du personnel");
      this.tabGrp6MetierChoix[4] = this.grp6MetierChoix.indexOf("Employé de bureau d'Assurance");
      this.tabGrp6MetierChoix[5] = this.grp6MetierChoix.indexOf("Horlogien");
      this.tabGrp6MetierChoix[6] = this.grp6MetierChoix.indexOf("Opticien");
      this.tabGrp6MetierChoix[7] = this.grp6MetierChoix.indexOf("Horticulteur");
      this.tabGrp6MetierChoix[8] = this.grp6MetierChoix.indexOf("Technicien des télécommunications");
      this.tabGrp6MetierChoix[9] = this.grp6MetierChoix.indexOf("Professeur de Mathématiques");
      this.tabGrp6MetierChoix[10] = this.grp6MetierChoix.indexOf("Botaniste");
      this.tabGrp6MetierChoix[11] = this.grp6MetierChoix.indexOf("Représentant de Commerce");
      //console.log(this.tabGrp6MetierChoix[6])

    }

    if (cible == 7) {
      this.tabGrp7MetierChoix[0] = this.grp7MetierChoix.indexOf("Critique Littéraire");
      this.tabGrp7MetierChoix[1] = this.grp7MetierChoix.indexOf("Disquaire");
      this.tabGrp7MetierChoix[2] = this.grp7MetierChoix.indexOf("Animateur de Centre Aéré");
      this.tabGrp7MetierChoix[3] = this.grp7MetierChoix.indexOf("Employé de Bureau");
      this.tabGrp7MetierChoix[4] = this.grp7MetierChoix.indexOf("Plombier");
      this.tabGrp7MetierChoix[5] = this.grp7MetierChoix.indexOf("Radiologue");
      this.tabGrp7MetierChoix[6] = this.grp7MetierChoix.indexOf("Jardinier");
      this.tabGrp7MetierChoix[7] = this.grp7MetierChoix.indexOf("Mécanicien Garagiste");
      this.tabGrp7MetierChoix[8] = this.grp7MetierChoix.indexOf("Programmeur");
      this.tabGrp7MetierChoix[9] = this.grp7MetierChoix.indexOf("Astronome");
      this.tabGrp7MetierChoix[10] = this.grp7MetierChoix.indexOf("Commissaire Priseur");
      this.tabGrp7MetierChoix[11] = this.grp7MetierChoix.indexOf("Créateur de Décors de Théâtre");
      //console.log(this.tabGrp7MetierChoix[7])

    }

    if (cible == 8) {
      this.tabGrp8MetierChoix[0] = this.grp8MetierChoix.indexOf("Guitariste");
      this.tabGrp8MetierChoix[1] = this.grp8MetierChoix.indexOf("Conseiller d'Orientation");
      this.tabGrp8MetierChoix[2] = this.grp8MetierChoix.indexOf("Employé des Postes");
      this.tabGrp8MetierChoix[3] = this.grp8MetierChoix.indexOf("Réparateur de bijoux");
      this.tabGrp8MetierChoix[4] = this.grp8MetierChoix.indexOf("Kinésithérapeute");
      this.tabGrp8MetierChoix[5] = this.grp8MetierChoix.indexOf("Guide Touristique");
      this.tabGrp8MetierChoix[6] = this.grp8MetierChoix.indexOf("Technicien Hi fi/Vidéo");
      this.tabGrp8MetierChoix[7] = this.grp8MetierChoix.indexOf("Commissaire aux Comptes");
      this.tabGrp8MetierChoix[8] = this.grp8MetierChoix.indexOf("Géologue");
      this.tabGrp8MetierChoix[9] = this.grp8MetierChoix.indexOf("Agent de Télé-Marketing");
      this.tabGrp8MetierChoix[10] = this.grp8MetierChoix.indexOf("Décorateur de Vitrines");
      this.tabGrp8MetierChoix[11] = this.grp8MetierChoix.indexOf("Scénariste");
      //console.log(this.tabGrp8MetierChoix[8])

    }

    if (cible == 9) {
      this.tabGrp9MetierChoix[0] = this.grp9MetierChoix.indexOf("Prêtre");
      this.tabGrp9MetierChoix[1] = this.grp9MetierChoix.indexOf("Commis de Bureau");
      this.tabGrp9MetierChoix[2] = this.grp9MetierChoix.indexOf("Boulangier");
      this.tabGrp9MetierChoix[3] = this.grp9MetierChoix.indexOf("Infirmier");
      this.tabGrp9MetierChoix[4] = this.grp9MetierChoix.indexOf("Maraîcher");
      this.tabGrp9MetierChoix[5] = this.grp9MetierChoix.indexOf("Ajusteur");
      this.tabGrp9MetierChoix[6] = this.grp9MetierChoix.indexOf("Caissier de Banque");
      this.tabGrp9MetierChoix[7] = this.grp9MetierChoix.indexOf("Technicien de laboratoire");
      this.tabGrp9MetierChoix[8] = this.grp9MetierChoix.indexOf("Vendeur Démonstrateur");
      this.tabGrp9MetierChoix[9] = this.grp9MetierChoix.indexOf("Couturier");
      this.tabGrp9MetierChoix[10] = this.grp9MetierChoix.indexOf("Poète");
      this.tabGrp9MetierChoix[11] = this.grp9MetierChoix.indexOf("Vendeur d'instrument de Musique");
      //console.log(this.tabGrp9MetierChoix[9])

    }

  }
  goToDivP() {
    if (this.compteurGrpeM != 1) {
      this.compteurGrpeM -= 1
      this.lastDiv = false;
      this.contentNext = "Suivant";
      this.isEndTest = false;
      this.valueProgress = Math.trunc(this.compteurGrpeM * (100 / 9))-3
      //console.log("compteur", this.compteurGrpeM)
      this.cpteur = 0
      this.isDisabled = true
    }
    else {
      this.firstDiv = true;
      this.cpteur = 0
      this.isDisabled = true

    }

  }

  goToDivN() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.cpteur = 0
    this.isDisabled = true
    /*
    this.isDisabled = !this.isDisabled
    setTimeout(function () {
      alert("Sup!");
      this.isDisabled = false
    }, 5000);//wait 2 seconds

    this.isDisabled = !this.isDisabled

   */
    // Display a confirmation prompt
    var result = confirm("Votre orientation dans votre filière de préinscription en dépendra :\nConfirmez-vous vraiment que vous avez classé ces métiers selon vos centres d'intérêt ?");

    if (result == true) {
      this.firstDiv = false;
      if (this.compteurGrpeM + 1 <= 9) {
        this.updateWeight(this.compteurGrpeM)
        this.compteurGrpeM += 1
        this.contentNext = "Suivant";
        this.firstDiv = false;

        //console.log("compteur", this.compteurGrpeM)

        this.valueProgress = Math.trunc(this.compteurGrpeM * (100 / 9))-3

      }
      else {
        this.updateWeight(this.compteurGrpeM)
        this.contentNext = "Terminer";
        this.lastDiv = false;
        this.compteurGrpeM += 1;
          if(this.metierCle1.value.length!=0 && this.metierCle2.value.length!=0 && this.metierCle3.value.length!=0){
            this.isEndTest = true;
            this.valueProgress =100;
          }
          else{
            var result = confirm("Veuiller remplir au clavier chacun des trois champs réservés aux métiers de votre préférence !");
            this.compteurGrpeM -= 1;

          }
        

      }

      if (this.compteurGrpeM == 9) {
        this.contentNext = "Terminer";
        this._CLY = new Date().toLocaleDateString().substring(6, 10);
        this.isErrorResultTest = false;
      }
    }

  }

  finTest2IRMR() {
    // Display a confirmation prompt
    var result = confirm("K3 is the best ! :)");
    window.location.href = 'https://destiny-innovation.com';

  }
  ouvrir(){
    // Display a confirmation prompt
    this.demostate="active"
    this.demostatebool=true;
  }
  fermer(){
    // Display a confirmation prompt
    this.demostate=""
    this.demostatebool=false;
  }
  shortAZTable(tab: number[], sizeTab: number) {
    let tampon = 0, v = 0;
    do {
      for (let i = 0; i < sizeTab; i++) { 
        if (tab[i] > tab[i + 1]) {
          tampon = tab[i];
          tab[i] = tab[i + 1];
          tab[i + 1] = tampon;
        }
      }
      v++;
    } while (v < sizeTab);

  }

  etalonageTestIRMR() {
    

    //console.log("Choice...", this.metierCle1)

    if (1 == 1) {
      this.tabTotal[0] = this.tabGrp1MetierChoix[0] + this.tabGrp2MetierChoix[11]
        + this.tabGrp3MetierChoix[10] + this.tabGrp4MetierChoix[9]
        + this.tabGrp5MetierChoix[8] + this.tabGrp6MetierChoix[7]
        + this.tabGrp7MetierChoix[6] + this.tabGrp8MetierChoix[5]
        + this.tabGrp9MetierChoix[4];

      this.tabTotal[1] = this.tabGrp1MetierChoix[1] + this.tabGrp2MetierChoix[0]
        + this.tabGrp3MetierChoix[11] + this.tabGrp4MetierChoix[10]
        + this.tabGrp5MetierChoix[9] + this.tabGrp6MetierChoix[8]
        + this.tabGrp7MetierChoix[7] + this.tabGrp8MetierChoix[6]
        + this.tabGrp9MetierChoix[5];

      this.tabTotal[2] = this.tabGrp1MetierChoix[2] + this.tabGrp2MetierChoix[1]
        + this.tabGrp3MetierChoix[0] + this.tabGrp4MetierChoix[11]
        + this.tabGrp5MetierChoix[10] + this.tabGrp6MetierChoix[9]
        + this.tabGrp7MetierChoix[8] + this.tabGrp8MetierChoix[7]
        + this.tabGrp9MetierChoix[6];

      this.tabTotal[3] = this.tabGrp1MetierChoix[3] + this.tabGrp2MetierChoix[2]
        + this.tabGrp3MetierChoix[1] + this.tabGrp4MetierChoix[0]
        + this.tabGrp5MetierChoix[11] + this.tabGrp6MetierChoix[10]
        + this.tabGrp7MetierChoix[9] + this.tabGrp8MetierChoix[8]
        + this.tabGrp9MetierChoix[7];

      this.tabTotal[4] = this.tabGrp1MetierChoix[4] + this.tabGrp2MetierChoix[3]
        + this.tabGrp3MetierChoix[2] + this.tabGrp4MetierChoix[1]
        + this.tabGrp5MetierChoix[0] + this.tabGrp6MetierChoix[11]
        + this.tabGrp7MetierChoix[10] + this.tabGrp8MetierChoix[9]
        + this.tabGrp9MetierChoix[8];

      this.tabTotal[5] = this.tabGrp1MetierChoix[5] + this.tabGrp2MetierChoix[4]
        + this.tabGrp3MetierChoix[3] + this.tabGrp4MetierChoix[2]
        + this.tabGrp5MetierChoix[1] + this.tabGrp6MetierChoix[0]
        + this.tabGrp7MetierChoix[11] + this.tabGrp8MetierChoix[10]
        + this.tabGrp9MetierChoix[9];

      this.tabTotal[6] = this.tabGrp1MetierChoix[6] + this.tabGrp2MetierChoix[5]
        + this.tabGrp3MetierChoix[4] + this.tabGrp4MetierChoix[3]
        + this.tabGrp5MetierChoix[2] + this.tabGrp6MetierChoix[1]
        + this.tabGrp7MetierChoix[0] + this.tabGrp8MetierChoix[11]
        + this.tabGrp9MetierChoix[10];

      this.tabTotal[7] = this.tabGrp1MetierChoix[7] + this.tabGrp2MetierChoix[6]
        + this.tabGrp3MetierChoix[5] + this.tabGrp4MetierChoix[4]
        + this.tabGrp5MetierChoix[3] + this.tabGrp6MetierChoix[2]
        + this.tabGrp7MetierChoix[1] + this.tabGrp8MetierChoix[0]
        + this.tabGrp9MetierChoix[11];

      this.tabTotal[8] = this.tabGrp1MetierChoix[8] + this.tabGrp2MetierChoix[7]
        + this.tabGrp3MetierChoix[6] + this.tabGrp4MetierChoix[5]
        + this.tabGrp5MetierChoix[4] + this.tabGrp6MetierChoix[3]
        + this.tabGrp7MetierChoix[2] + this.tabGrp8MetierChoix[1]
        + this.tabGrp9MetierChoix[0];

      this.tabTotal[9] = this.tabGrp1MetierChoix[9] + this.tabGrp2MetierChoix[8]
        + this.tabGrp3MetierChoix[7] + this.tabGrp4MetierChoix[6]
        + this.tabGrp5MetierChoix[5] + this.tabGrp6MetierChoix[4]
        + this.tabGrp7MetierChoix[3] + this.tabGrp8MetierChoix[2]
        + this.tabGrp9MetierChoix[1];

      this.tabTotal[10] = this.tabGrp1MetierChoix[10] + this.tabGrp2MetierChoix[9]
        + this.tabGrp3MetierChoix[8] + this.tabGrp4MetierChoix[7]
        + this.tabGrp5MetierChoix[6] + this.tabGrp6MetierChoix[5]
        + this.tabGrp7MetierChoix[4] + this.tabGrp8MetierChoix[3]
        + this.tabGrp9MetierChoix[2];

      this.tabTotal[11] = this.tabGrp1MetierChoix[11] + this.tabGrp2MetierChoix[10]
        + this.tabGrp3MetierChoix[9] + this.tabGrp4MetierChoix[8]
        + this.tabGrp5MetierChoix[7] + this.tabGrp6MetierChoix[6]
        + this.tabGrp7MetierChoix[5] + this.tabGrp8MetierChoix[4]
        + this.tabGrp9MetierChoix[3];

      this.TypeHollandR = this.tabTotal[10] + this.tabTotal[1] + this.tabTotal[0];
      this.TypeHollandI = this.tabTotal[11] + this.tabTotal[3];
      this.TypeHollandA = this.tabTotal[5] + this.tabTotal[6] + this.tabTotal[7];
      this.TypeHollandS = this.tabTotal[8];
      this.TypeHollandE = this.tabTotal[4];
      this.TypeHollandC = this.tabTotal[2] + this.tabTotal[9];

      this.tabTotalTypeH[0] = this.TypeHollandR;
      this.tabTotalTypeH[1] = this.TypeHollandI;
      this.tabTotalTypeH[2] = this.TypeHollandA;
      this.tabTotalTypeH[3] = this.TypeHollandS;
      this.tabTotalTypeH[4] = this.TypeHollandE;
      this.tabTotalTypeH[5] = this.TypeHollandC;

      //Détermination du type de personnalité Holland
      if ((this.TypeHollandR <= this.TypeHollandI) && (this.TypeHollandR <= this.TypeHollandA) &&
        (this.TypeHollandR <= this.TypeHollandS) && (this.TypeHollandR <= this.TypeHollandE) &&
        (this.TypeHollandR <= this.TypeHollandC)) {

        this.resultClasseCat = "Réaliste";
      }

      if ((this.TypeHollandI <= this.TypeHollandR) && (this.TypeHollandI <= this.TypeHollandA) &&
        (this.TypeHollandI <= this.TypeHollandS) && (this.TypeHollandI <= this.TypeHollandE) &&
        (this.TypeHollandI <= this.TypeHollandC)) {

        this.resultClasseCat = "Investigateur";
      }
      if ((this.TypeHollandA <= this.TypeHollandI) && (this.TypeHollandA <= this.TypeHollandR) &&
        (this.TypeHollandA <= this.TypeHollandS) && (this.TypeHollandA <= this.TypeHollandE) &&
        (this.TypeHollandA <= this.TypeHollandC)) {

        this.resultClasseCat = "Artistique";

      }
      if ((this.TypeHollandS <= this.TypeHollandI) && (this.TypeHollandS <= this.TypeHollandA) &&
        (this.TypeHollandS <= this.TypeHollandR) && (this.TypeHollandS <= this.TypeHollandE) &&
        (this.TypeHollandS <= this.TypeHollandC)) {

        this.resultClasseCat = "Social";
      }

      if ((this.TypeHollandE <= this.TypeHollandI) && (this.TypeHollandE <= this.TypeHollandA) &&
        (this.TypeHollandE <= this.TypeHollandS) && (this.TypeHollandE <= this.TypeHollandR) &&
        (this.TypeHollandE <= this.TypeHollandC)) {

        this.resultClasseCat = "Entreprenant";
      }

      if ((this.TypeHollandC <= this.TypeHollandI) && (this.TypeHollandC <= this.TypeHollandA) &&
        (this.TypeHollandC <= this.TypeHollandS) && (this.TypeHollandC <= this.TypeHollandE) &&
        (this.TypeHollandC <= this.TypeHollandR)) {

        this.resultClasseCat = "Conventionnel";
      }

      // Prepare data to send
      this.testResult = "General ->" + "TypeHolland:" + this.resultClasseCat + ";" +
        "R:" + this.TypeHollandR + "~" +
        "I:" + this.TypeHollandI + "~" +
        "A:" + this.TypeHollandA + "~" +
        "S:" + this.TypeHollandS + "~" +
        "E:" + this.TypeHollandE + "~" +
        "C:" + this.TypeHollandC + ";" +
        "Data-Tab-Total:" + this.tabTotal[0] + "~" + this.tabTotal[1] + "~" + this.tabTotal[2] + "~" +
        this.tabTotal[3] + "~" + this.tabTotal[4] + "~" + this.tabTotal[5] + "~" +
        this.tabTotal[6] + "~" + this.tabTotal[7] + "~" + this.tabTotal[8] + "~" +
        this.tabTotal[9] + "~" + this.tabTotal[10] + "~" + this.tabTotal[11] + ";"
      "Rang Grp1 metier:" + this.tabGrp1MetierChoix[0] + "~" + this.tabGrp1MetierChoix[1] + "~" +
        this.tabGrp1MetierChoix[2] + "~" + this.tabGrp1MetierChoix[3] + "~" +
        this.tabGrp1MetierChoix[4] + "~" + this.tabGrp1MetierChoix[5] + "~" +
        this.tabGrp1MetierChoix[6] + "~" + this.tabGrp1MetierChoix[7] + "~" +
        this.tabGrp1MetierChoix[8] + "~" + this.tabGrp1MetierChoix[9] + "~" +
        this.tabGrp1MetierChoix[10] + "~" + this.tabGrp1MetierChoix[11] + ";"
      "Rang Grp2 metier:" + this.tabGrp2MetierChoix[0] + "~" + this.tabGrp2MetierChoix[1] + "~" +
        this.tabGrp2MetierChoix[2] + "~" + this.tabGrp2MetierChoix[3] + "~" +
        this.tabGrp2MetierChoix[4] + "~" + this.tabGrp2MetierChoix[5] + "~" +
        this.tabGrp2MetierChoix[6] + "~" + this.tabGrp2MetierChoix[7] + "~" +
        this.tabGrp2MetierChoix[8] + "~" + this.tabGrp2MetierChoix[9] + "~" +
        this.tabGrp2MetierChoix[10] + "~" + this.tabGrp2MetierChoix[11] + ";"
      "Rang Grp3 metier:" + this.tabGrp3MetierChoix[0] + "~" + this.tabGrp3MetierChoix[1] + "~" +
        this.tabGrp3MetierChoix[2] + "~" + this.tabGrp3MetierChoix[3] + "~" +
        this.tabGrp3MetierChoix[4] + "~" + this.tabGrp3MetierChoix[5] + "~" +
        this.tabGrp3MetierChoix[6] + "~" + this.tabGrp3MetierChoix[7] + "~" +
        this.tabGrp3MetierChoix[8] + "~" + this.tabGrp3MetierChoix[9] + "~" +
        this.tabGrp3MetierChoix[10] + "~" + this.tabGrp3MetierChoix[11] + ";"
      "Rang Grp4 metier:" + this.tabGrp4MetierChoix[0] + "~" + this.tabGrp4MetierChoix[1] + "~" +
        this.tabGrp4MetierChoix[2] + "~" + this.tabGrp4MetierChoix[3] + "~" +
        this.tabGrp4MetierChoix[4] + "~" + this.tabGrp4MetierChoix[5] + "~" +
        this.tabGrp4MetierChoix[6] + "~" + this.tabGrp4MetierChoix[7] + "~" +
        this.tabGrp4MetierChoix[8] + "~" + this.tabGrp4MetierChoix[9] + "~" +
        this.tabGrp4MetierChoix[10] + "~" + this.tabGrp4MetierChoix[11] + ";"
      "Rang Grp5 metier:" + this.tabGrp5MetierChoix[0] + "~" + this.tabGrp5MetierChoix[1] + "~" +
        this.tabGrp5MetierChoix[2] + "~" + this.tabGrp5MetierChoix[3] + "~" +
        this.tabGrp5MetierChoix[4] + "~" + this.tabGrp5MetierChoix[5] + "~" +
        this.tabGrp5MetierChoix[6] + "~" + this.tabGrp5MetierChoix[7] + "~" +
        this.tabGrp5MetierChoix[8] + "~" + this.tabGrp5MetierChoix[9] + "~" +
        this.tabGrp5MetierChoix[10] + "~" + this.tabGrp5MetierChoix[11] + ";"
      "Rang Grp6 metier:" + this.tabGrp6MetierChoix[0] + "~" + this.tabGrp6MetierChoix[1] + "~" +
        this.tabGrp6MetierChoix[2] + "~" + this.tabGrp6MetierChoix[3] + "~" +
        this.tabGrp6MetierChoix[4] + "~" + this.tabGrp6MetierChoix[5] + "~" +
        this.tabGrp6MetierChoix[6] + "~" + this.tabGrp6MetierChoix[7] + "~" +
        this.tabGrp6MetierChoix[8] + "~" + this.tabGrp6MetierChoix[9] + "~" +
        this.tabGrp6MetierChoix[10] + "~" + this.tabGrp6MetierChoix[11] + ";"
      "Rang Grp7 metier:" + this.tabGrp7MetierChoix[0] + "~" + this.tabGrp7MetierChoix[1] + "~" +
        this.tabGrp7MetierChoix[2] + "~" + this.tabGrp7MetierChoix[3] + "~" +
        this.tabGrp7MetierChoix[4] + "~" + this.tabGrp7MetierChoix[5] + "~" +
        this.tabGrp7MetierChoix[6] + "~" + this.tabGrp7MetierChoix[7] + "~" +
        this.tabGrp7MetierChoix[8] + "~" + this.tabGrp7MetierChoix[9] + "~" +
        this.tabGrp7MetierChoix[10] + "~" + this.tabGrp7MetierChoix[11] + ";"
      "Rang Grp8 metier:" + this.tabGrp8MetierChoix[0] + "~" + this.tabGrp8MetierChoix[1] + "~" +
        this.tabGrp8MetierChoix[2] + "~" + this.tabGrp8MetierChoix[3] + "~" +
        this.tabGrp8MetierChoix[4] + "~" + this.tabGrp8MetierChoix[5] + "~" +
        this.tabGrp8MetierChoix[6] + "~" + this.tabGrp8MetierChoix[7] + "~" +
        this.tabGrp8MetierChoix[8] + "~" + this.tabGrp8MetierChoix[9] + "~" +
        this.tabGrp8MetierChoix[10] + "~" + this.tabGrp8MetierChoix[11] + ";"
      "Rang Grp9 metier:" + this.tabGrp9MetierChoix[0] + "~" + this.tabGrp9MetierChoix[1] + "~" +
        this.tabGrp9MetierChoix[2] + "~" + this.tabGrp9MetierChoix[3] + "~" +
        this.tabGrp9MetierChoix[4] + "~" + this.tabGrp9MetierChoix[5] + "~" +
        this.tabGrp9MetierChoix[6] + "~" + this.tabGrp9MetierChoix[7] + "~" +
        this.tabGrp9MetierChoix[8] + "~" + this.tabGrp9MetierChoix[9] + "~" +
        this.tabGrp9MetierChoix[10] + "~" + this.tabGrp9MetierChoix[11] + ";";
      //console.log("Content of result : ", this.testResult);

      /*
      this.remoteService.postTestResult(this.idUser, this.testResult, "IRMR").subscribe(
        data => {
          //console.log(data);
        },
        //error => this.error = error.message
      );

      */


     //console.log("1...", this.metierCle1.value);
     //console.log("1...type    ", typeof(this.metierCle1));
     //console.log("2...", this.metierCle2.value);
     //console.log("3...", this.metierCle3.value);



      this.http.get(this.dataSave.lien +'Destiny_add_resultirmr.php?resultClasseCat='+this.resultClasseCat+'&TypeHollandR='+this.TypeHollandR+'&TypeHollandI='+this.TypeHollandI+'&TypeHollandA='+this.TypeHollandA
      +'&TypeHollandS='+this.TypeHollandS+'&TypeHollandE='+this.TypeHollandE+'&TypeHollandC='+this.TypeHollandC+'&choix1metieruser='+this.metierCle1.value+'&choix2metieruser='+this.metierCle2.value+'&choix3metieruser='+this.metierCle3.value
      +'&datetest='+"pp"+'&userID='+"-"+'&activated='+1+'&deleted='+0+'&updated='+1).map((data1: any) => data1.json())
    
      .subscribe(data => {
  
        this.mycode=data.code;   
        //this.showNotification('top', 'center', 'Enregistrement effectué', 1);

        if(this.mycode.length!=0){
          this.verifResult=true;
        }
        this.isresulTestIRMR = true;
      },error => {
        var result = confirm("Problème de connexion avec le serveur : veuiller réessayer ! "); 
    });
  

      for (let i = 0; i < this.tabTotal.length; i++) {
        this.tabRang[i] = this.tabTotal[i];
      }

      // Test impression eléments:
      /*
      ////console.log('Somme R est : ',this.TypeHollandR);
      ////console.log('Somme I est : ',this.TypeHollandI);
      ////console.log('Somme A est : ',this.TypeHollandA);
      ////console.log('Somme S est : ',this.TypeHollandS);
      ////console.log('Somme E est : ',this.TypeHollandE);
      ////console.log('Somme C est : ',this.TypeHollandC);
      
      */

      // Test impression eléments:
      for (let i = 0; i < this.tabTotal.length; i++) {
        //////console.log('tabTotal, életment ',i,'est ', this.tabTotal[i]);
      }
      this.shortAZTable(this.tabRang, this.tabRang.length);


      // Test impression eléments triés:
      for (let i = 0; i < this.tabRang.length; i++) {
        //////console.log('tabRang, életment ',i,'est ', this.tabRang[i]);
      }
    }
    else {
      //this.isErrorResultTest = true;
    }

  }

  finTestIRMR() {

    //this.navCtrl.push(MeConnaitrePage);
  }

  contactCO() {
    // this.router.navigate(['tchat'], { relativeTo: this.route });

  }



}
