<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Preinscription en ligne</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Preinscription en ligne</li>
            </ul>
        </div>
    </div>
</section>



<section class="blog-area ptb-100">
    <div class="container"> 
        <div class="about-inner-area">
            <div class="row align-items-center">
              
                    <div class="about-content-two">
                        <span class="sub-title align-items-center">Préalables</span>
                        <h2 class="align-items-center">A faire avant la preinscription en ligne </h2>
 
                        <ul class="features-list">
                            <li><span><i class='bx bx-check-double'></i> Parcourir les filières de formations</span></li>
                            <li><span><i class='bx bx-check-double'></i> Passer les tests d'orientation</span></li>
                            <li><span><i class='bx bx-check-double'></i> Valider le choix de ma filière avec un conseiller</span></li>
                            <li><span><i class='bx bx-check-double'></i> Noter le numéro de la fiche d'orientation</span></li>
                        </ul>
                    </div> 

                 
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container"> 
        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">Preinscriptions en ligne</span>
                        <h2>Procédure de préinscription en ligne</h2>
 
                        <ul class="features-list">
                            <li><span><i class='bx bx-check-double'></i> Vous êtes déjà sur le site internet des preinscriptions</span></li>
                            <li><span><i class='bx bx-check-double'></i> Cliquer sur « ici », puis renseigner vos informations signalétiques</span></li>
                            <li><span><i class='bx bx-check-double'></i> Vérifier ces informations et enregistrer en cliquant sur le bouton « validation »</span></li>
                            <li><span><i class='bx bx-check-double'></i> Après la validation, imprimer la fiche obtenue qui contient votre identifiant unique</span></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/universite.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="blog-area ptb-100">
    <div class="container"> 
        <div class="about-inner-area">
            <div class="row align-items-center">
              
                    <div class="about-content-two">
                        <span class="sub-title align-items-center">Commencer</span>
                        <h2 class="align-items-center"> Cliquez sur un de ces liens pour commencer la préinscription en ligne. </h2>
 
                         <div class="btn-box">
                                <a href="http://www.preinscriptions.uninet.cm/dossier_univs/new/falsh" class="optional-btn" target="_blank"><i class="flaticon-open-book"></i> Faculté des arts, lettres et sciences humaines</a>
                                <br><br>
                                <a href="http://www.preinscriptions.uninet.cm/dossier_univs/new/fs" class="optional-btn" target="_blank"><i class="flaticon-open-book"></i> Faculté des sciences</a>
                                <br><br>
                                <a href="http://www.preinscriptions.uninet.cm/dossier_univs/new/fse" class="optional-btn" target="_blank"><i class="flaticon-open-book"></i> Faculté des sciences de l'éducation</a>
                            </div>
                    </div> 
                    

                 
            </div>
        </div>
    </div>
</section>