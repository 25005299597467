<div class="row">
    <div class="normes-ul-admission admission-mince admission-ul">
        <div class="admission-entete">
            <h2 style="color: #fff">Préinscriptions 2020</h2>
            <p style="color: #fff">La réalisation de votre projet d’études commence ici.</p>
        </div>
        <div class="admission-contenu">
            <div class="admission-etapes">
                <a routerLink="/filieres">
                    <div class="admission-numero">1</div>
                    <div class="admission-cta">Parcourez<br> <br>nos filières</div>
                </a>
                <a routerLink="/orientation">
                    <div class="admission-numero">2</div>
                    <div class="admission-cta">Procédez <br><br>aux tests <br><br> d'orientation</div>
                </a>
                <a routerLink="/preinscriptionsenligne">
                    <div class="admission-numero">3</div>
                    <div class="admission-cta">Procédez à  <br><br>l'inscription <br><br>en ligne</div>
                </a>
                <a routerLink="/deposdossiers">
                    <div class="admission-numero">4</div>
                    <div class="admission-cta">Déposez <br><br>votre<br><br> dossier<br><br> physique</div>
                </a>
            </div>
            <a class="admission-question" routerLink="/faq">
                <div class="contenu-question">
                    <div class="texte-question">Questions</div>
                    <div class="texte-question">?</div>
                </div>
            </a>
        </div>
    </div>
</div>
