import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deposdossiers',
  templateUrl: './deposdossiers.component.html',
  styleUrls: ['./deposdossiers.component.scss']
})
export class DeposdossiersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
