<footer class="footer-area">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <h2>Subscribe To Our Newsletter</h2>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL">
                        <button type="submit">Subscribe Now <i class="flaticon-plane"></i></button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>
                    <p>OMDES - Observatoire National des Métiers des Diplomés de l'Enseignement Supérieur</p>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Liens utiles</h3>
                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/orientation">Orientation en ligne</a></li>
                        <li><a routerLink="/facultes">Facultés</a></li>
                        <li><a routerLink="/filieres">Filières</a></li>
                        <li><a routerLink="/faq">FAQ's</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contacts</h3>
                    <ul class="footer-contact-info">
                        <li><span>Address:</span> 755 Yaounde, Cameroon</li>
                        <li><span>Email:</span>  <a href="observatoiredesmetiersminesup@gmail.com">observatoiredesmetiersminesup@gmail.com</a></li>
                        <li><span>Phone:</span>  <a href="tel:+44587154756">  +237 222 22 13 20 </a></li>
                        <li><span>Fax:</span>  <a href="tel:+557854578964">  +237 222 22 13 20 </a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Jours ouvrables</h3>
                    <ul class="footer-working-days">
                        <li><span>Lundi:</span> 8AM - 6PM</li>
                        <li><span>Mardi:</span> 8AM - 6PM</li>
                        <li><span>Mercredi:</span> 8AM - 6PM</li>
                        <li><span>Jeudi :</span> 8AM - 6PM</li>
                        <li><span>Vendredi :</span> 8AM - 6PM</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Copyright <i class='bx bx-copyright'></i> 2020 MINESUP-OMDES. Designed by <a target="_blank" href="http://destiny-innovation.com">Destiny SARL</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-shape1"><img src="assets/img/footer-shape1.png" alt="image"></div>
    <div class="footer-shape2"><img src="assets/img/footer-shape2.png" alt="image"></div>
    <div class="footer-shape3"><img src="assets/img/footer-shape3.png" alt="image"></div>
</footer>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            
            <div class="search-overlay-close">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- <app-live-data></app-live-data> -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>