<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nos filières de formation</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Filières</li>
            </ul>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container"> 
        <app-liste-filiere></app-liste-filiere>
    </div>
</section>