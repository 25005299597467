<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Grid</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Covid</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                        <a href="#" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Adjust</span>
                        <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Coronavirus</span>
                        <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img4.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Spread</span>
                        <h3><a routerLink="/blog-details">New Africa coronavirus crisis looms with internal spread</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img5.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Globe</span>
                        <h3><a routerLink="/blog-details">Spain death toll up by almost 400 as cases rise around the globe</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img6.jpg" alt="image">
                        </a>
                        <div class="date">
                            <span>January 29, 2020</span>
                        </div>
                    </div>

                    <div class="post-content">
                        <span class="category">Mask</span>
                        <h3><a routerLink="/blog-details">Can a face mask protect me from coronavirus-2020?</a></h3>
                        <a routerLink="/blog-details" class="details-btn">Read Story</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <a routerLink="/blog-one" class="prev page-numbers"><i class='bx bx-chevron-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-one" class="page-numbers">2</a>
                    <a routerLink="/blog-one" class="page-numbers">3</a>
                    <a routerLink="/blog-one" class="page-numbers">4</a>
                    <a routerLink="/blog-one" class="page-numbers">5</a>
                    <a routerLink="/blog-one" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>