<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Depos du dossier physique</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Depos du dossier</li>
            </ul>
        </div>
    </div>
</section>


<section class="blog-area ptb-100">
    <div class="container">
        <div class="about-inner-area">
            <div class="row align-items-center">

                <div class="about-content-two">
                    <span class="sub-title align-items-center">Procedure</span>
                    <h2 class="align-items-center"> Procédure de dépôt physique du dossier </h2>
                    <p>Se rendre ensuite à la Faculté choisie le jour du rendez-vous muni des pièces suivantes pour le dépôt du dossier physique</p>
                    <ul class="features-list">
                        <li><span><i class='bx bx-check-double'></i> Copie Certifiée Conforme du Relevé de Notes du Baccalauréat</span></li>
                        <li><span><i class='bx bx-check-double'></i>Copie Certifiée Conforme du Probatoire/GCE-O Level ou de l’Attestation de Réussite</span></li>
                        <li><span><i class='bx bx-check-double'></i> Copie Certifiée Conforme du diplôme de Licence ou son équivalent pour l’accès à la Faculté des Sciences de l’Education</span></li>
                        <li><span><i class='bx bx-check-double'></i> Copie certifiée conforme de l’Acte de naissance</span></li>
                        <li><span><i class='bx bx-check-double'></i> Reçu de paiement des frais de préinscription qui s'élèvent à 10 000 FCFA</span></li>
                    </ul>
                </div>


            </div>
        </div>
    </div>
</section>