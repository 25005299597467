import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filieres',
  templateUrl: './filieres.component.html',
  styleUrls: ['./filieres.component.scss']
})
export class FilieresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
