
 
<section class="products-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Filières</span>
            <h2>Nos filières par catégories</h2>
        </div>

        <div class="shorting-menu">
             <button class="filter" data-filter="all">Toutes</button>
            <button class="filter" data-filter=".falsh">Faculté des Arts</button>
            <button class="filter" data-filter=".fs">Faculté des Sciences</button>
            <button class="filter" data-filter=".fse">Faculté des Sciences de l'Education</button>
            
        </div>
     
        <div class="shorting">
            <div class="row">
                <div  *ngFor='let filiere of filieres; let i = index' class="col-lg-3 col-md-6 col-sm-6 mix {{filiere.faculte }}">
                    <div class="single-products-box">
                        <div class="products-image">
                            <a href="{{ filiere.infolink }}" target="_blank">
                                <img src="{{ filiere.imagelink }}" alt="image">
                            </a>
                        </div>

                        <div class="products-content">
                            <h3><a href="{{ filiere.infolink }}" target="_blank">{{ filiere.nom }}</a></h3>
                            <a href="{{ filiere.infolink }}" class="new" target="_blank"> 
                            <div class="price">
                                
                                <span class="new"><i class="flaticon-open-book"></i> En savoir plus</span>
                              
                             </div>
                            </a>
                            <!-- <a href="{{ filiere.videolink }}" class="popup-youtube add-to-cart-btn"><i
                                class="flaticon-play-button"></i> vidéo</a>  -->
                        </div>
                    </div>
                </div>
 

   

 

 
 

  
              
            </div>
        </div>
    </div>
</section>
