<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</section>

<section class="about-area-two bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/about-img4.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">About Covid-19</span>
                        <h2>About Coronavirus Disease</h2>
                        <h6>We can support student forum 24/7 for national and international patient.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>
                        <p>Know the Symptoms of the Coronavirus(Covid-19) and Inform Others, Follow the Safety Steps Instructed by World Health Organization (WHO).</p>
                        <div class="features-text">
                            <h5><i class='bx bx-planet'></i>Most People Infected With The COVID-19 Virus</h5>
                            <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">Covid-19</span>
                        <h2>Know More About Covid-19</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>
                        <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others!</p>
                        <ul class="features-list">
                            <li><span><i class='bx bx-check-double'></i> Person to Person</span></li>
                            <li><span><i class='bx bx-check-double'></i> Infected Person Coughs</span></li>
                            <li><span><i class='bx bx-check-double'></i> Close Contact Another</span></li>
                            <li><span><i class='bx bx-check-double'></i> Wash Your Hands</span></li>
                            <li><span><i class='bx bx-check-double'></i> Avoid Close Contact</span></li>
                            <li><span><i class='bx bx-check-double'></i> Stay at Home</span></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/about-img5.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 image">
                    <div class="about-image-two">
                        <img src="assets/img/about-img6.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 content">
                    <div class="about-content-two">
                        <span class="sub-title">Protect Yourself</span>
                        <h2>How To Protect Yourself</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea commodo consequat.</p>
                        <p>Stay Home & Safe For You and Others, And Maintain All the Safety Measures by Medical Professionals to Defeat It!</p>
                        <p>Know the Symptoms of the Coronavirus(Covid-19) and Inform Others, Follow the Safety Steps Instructed by World Health Organization (WHO).</p>
                        <div class="features-text">
                            <h5><i class='bx bx-planet'></i>Most People Infected With The COVID-19 Virus</h5>
                            <p>Be Clean & Keep Clean, Maintain Social Distance, Avoid All Kinds of Crowds including Social Gathering, Protect Yourself and Others!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="symptoms-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="symptoms-image">
                    <img src="assets/img/symptoms-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="symptoms-content">
                    <span class="sub-title">Symptoms</span>
                    <h2>Coronavirus Symptoms</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <ul>
                        <li><span><i class='flaticon-tick'></i> Cough</span></li>
                        <li><span><i class='flaticon-tick'></i> Fever</span></li>
                        <li><span><i class='flaticon-tick'></i> Tiredness</span></li>
                        <li><span><i class='flaticon-tick'></i> Headache</span></li>
                        <li><span><i class='flaticon-tick'></i> Breath Shortness</span></li>
                        <li><span><i class='flaticon-tick'></i> Muscle Pain</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>COVID-19 Coronavirus Outbreak</h2>
        </div>
    </div>
        
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-image">
                    <img src="assets/img/funfacts2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="187">00</h3>
                                <p>Total Country</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="294110">00</h3>
                                <p>Confirmed Cases</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="12944">00</h3>
                                <p>Deaths</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box bg-white">
                                <div class="icon">
                                    <img src="assets/img/icon1.png" alt="image">
                                </div>
                                <h3 class="odometer" data-count="100345">00</h3>
                                <p>Recovered</p>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="funfacts-info color-black">
                                <p>Source: <a href="https://www.who.int/" target="_blank">WHO</a> on March 23, 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="funfacts-shape1"><img src="assets/img/map-shape.png" alt="image"></div>
</section>

<section class="doctors-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Doctors</span>
            <h2>Meet Specialist Doctors</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img1.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Addison Smith</h3>
                        <span>Nephrologists</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img2.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Sarah Taylor</h3>
                        <span>Infectious</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-doctors-box">
                    <div class="image">
                        <img src="assets/img/doctors/img3.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Dr. Aiken Ward</h3>
                        <span>Cardiologists</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>