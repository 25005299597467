import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prevention',
  templateUrl: './prevention.component.html',
  styleUrls: ['./prevention.component.scss']
})
export class PreventionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
