<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Orientation en ligne</h2>
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Orientation</li>
            </ul>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container"> 
        <app-irmr></app-irmr>
    </div>
</section>