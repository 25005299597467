import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { HomeFiveComponent } from './components/pages/home-five/home-five.component';
import { HomeSixComponent } from './components/pages/home-six/home-six.component';
import { AboutOneComponent } from './components/pages/about-one/about-one.component';
import { AboutTwoComponent } from './components/pages/about-two/about-two.component';
import { PreventionComponent } from './components/pages/prevention/prevention.component';
import { DoctorsComponent } from './components/pages/doctors/doctors.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { SpreadComponent } from './components/pages/spread/spread.component';
import { OutbreakComponent } from './components/pages/outbreak/outbreak.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { BlogOneComponent } from './components/pages/blog-one/blog-one.component';
import { BlogTwoComponent } from './components/pages/blog-two/blog-two.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { LiveTrackerComponent } from './components/pages/live-tracker/live-tracker.component';
import { OrientationComponent } from './components/pages/orientation/orientation.component';
import {FacultesComponent } from './components/pages/facultes/facultes.component';
import {FilieresComponent } from './components/pages/filieres/filieres.component';
import {PreinscriptionsComponent } from './components/pages/preinscriptions/preinscriptions.component';
import {DeposdossiersComponent } from './components/pages/deposdossiers/deposdossiers.component';
import {TchatComponent } from './components/pages/tchat/tchat.component';
const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'home-six', component: HomeSixComponent},
    {path: 'about-one', component: AboutOneComponent},
    {path: 'about-two', component: AboutTwoComponent},
    {path: 'prevention', component: PreventionComponent},
    {path: 'doctors', component: DoctorsComponent},
    {path: 'appointment', component: AppointmentComponent},
    {path: 'spread', component: SpreadComponent},
    {path: 'outbreak', component: OutbreakComponent},
    {path: 'products', component: ProductsComponent},
    {path: 'cart', component: CartComponent},
    {path: 'checkout', component: CheckoutComponent},
    {path: 'products-details', component: ProductsDetailsComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'blog-one', component: BlogOneComponent},
    {path: 'blog-two', component: BlogTwoComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'live-tracker', component: LiveTrackerComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'orientation', component: OrientationComponent},
    {path: 'facultes', component: FacultesComponent},
    {path: 'filieres', component: FilieresComponent},
    {path: 'preinscriptionsenligne', component: PreinscriptionsComponent},
    {path: 'deposdossiers', component: DeposdossiersComponent},
    {path: 'tchat', component: TchatComponent},
];

@NgModule({
    
    //imports: [RouterModule.forRoot(routes)],
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }