<header class="header-area">

    <!-- Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="top-header-social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-8 col-md-12">
                    <ul class="top-header-contact-info">
                        <li><i class='bx bx-envelope'></i> observatoiredesmetiersminesup@gmail.com | 24/24H.7/7J                        </li>
                        <li><i class='bx bx-phone-call'></i> Téléphone: <a href="tel:+237222221320">+237 222 22 13 20</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="qovid-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/img/logo.png" alt="logo">
                    </a>

                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>            

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav"> 
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Accueil</a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/orientation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Orientation en ligne </a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/facultes" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Facultés </a></li>
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/filieres" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Filières </a></li>
             
                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                        

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        </ul>

                        <div class="others-option d-flex align-items-center">
                            <!-- <div class="option-item">
                                <div class="cart-btn">
                                    <a routerLink="/cart"><i class='bx bx-shopping-bag'></i><span>3</span></a>
                                </div>
                            </div> -->

                            <div class="option-item">
                                <div class="search-btn-box">
                                    <i class="search-btn bx bx-search-alt"></i>
                                </div>
                            </div>

                            <div class="option-item">
                                <a routerLink="/tchat" class="default-btn"><i class="flaticon-doctor"></i> Consulter un conseiller en ligne </a> 
                   
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->
    
</header>