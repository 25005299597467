import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-filiere',
  templateUrl: './liste-filiere.component.html',
  styleUrls: ['./liste-filiere.component.scss']
})
export class ListeFiliereComponent implements OnInit {

  constructor() { }

  filieres: Array<Object> = [
    {
      nom: 'ALLEMAND',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=708',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'AMÉNAGEMENT TOURISTIQUE DES TERRITOIRES',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=733',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'ANGLAIS',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=709',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'ANTHROPOLOGIE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=714',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'ARCHÉOLOGIES ET GESTION DU PATRIMOINE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=724',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'ART DU SPECTACLE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=724',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'ART PLASTIQUE ET HISTOIRE DE L’ART',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=723',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'ESPAGNOL',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=710',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'GENRE ET DÉVELOPPEMENT',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=730',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'GUIDE TOURISTIQUE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=725',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'GUIDE TOURISTIQUE ET MANAGEMENT DES ENTREPRISES TOURISTIQUE ET HÔTELIÈRES',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=734',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'HISTOIRE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=716',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LANGUE AFRICAINE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=729',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LANGUES AFRICAINES ET LINGUISTIQUE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=717',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LANGUES, LITTÉRATURE ET CIVILISATIONS GERMANIQUES',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2859',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LANGUES, LITTÉRATURE ET CIVILISATIONS IBÉRIQUES, IBÉRO-AMÉRICAINES ET ITALIENNES',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2858',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LETTRES BILINGUES',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=711',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LETTRES BILINGUES (ANGLOPHONE)',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2857',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LINGUISTIQUE GÉNÉRALE APPLIQUÉE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=718',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'LITTÉRATURE ET CIVILISATION AFRICAINE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=713',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'MANAGEMENT DES ENTREPRISES TOURISTIQUES',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=726',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PHILOSOPHIE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=719',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PRODUCTION CINÉMATOGRAPHIQUE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=732',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PSYCHOLOGIE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=720',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PSYCHOLOGIE APPLIQUÉE À L’ENFANT ET À L’ADOLESCENT',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=738',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PSYCHOLOGIE CLINIQUE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=736',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PSYCHOLOGIE DU TRAVAIL DES L’ORGANISATION',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=737',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'SCIENCES DU LANGAGE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2860',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'SCIENCES SOCIALES POUR LE DÉVELOPPEMENT',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=731',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'SÉMIOTIQUE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=740',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'SOCIOLOGIE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=721',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    },
    {
      nom: 'SYSTÈME D’INFORMATION GÉOGRAPHIQUE',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=728',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'URBANISME, AMÉNAGEMENT, DÉVELOPPEMENT URBAIN',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=727',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'TRADUCTION ET INTERPRÉTATION',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=735',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    } ,
    {
      nom: 'URBANISME, AMÉNAGEMENT, DÉVELOPPEMENT URBAIN',
      faculte: 'falsh',
      imagelink: 'assets/img/masque5.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=727',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'BIOCHIMIE',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=742',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'BIOLOGIE ANIMALE',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=741',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'BIOLOGIE ET PHYSIOLOGIE VÉGÉTALE',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=743',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'CHIMIE',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=744',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'GÉOLOGIE (SCIENCES DE LA TERRE)',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=748',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'INFORMATIQUE',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=745',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'MATHÉMATIQUES',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=746',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'PHYSIQUE',
      faculte: 'fs',
      imagelink: 'assets/img/masque6.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=747',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'CURRICULUM ET ÉVALUATION',
      faculte: 'fse',
      imagelink: 'assets/img/masque8.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2853',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'DIDACTIQUE DES DISCIPLINES',
      faculte: 'fse',
      imagelink: 'assets/img/masque8.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2852',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'ENSEIGNEMENTS FONDAMENTAUX EN ÉDUCATION',
      faculte: 'fse',
      imagelink: 'assets/img/masque8.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2856',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'INTERVENTION, ORIENTATION ET ÉDUCATION EXTRASCOLAIRE',
      faculte: 'fse',
      imagelink: 'assets/img/masque8.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2855',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
    ,
    {
      nom: 'MANAGEMENT DE L’ÉDUCATION',
      faculte: 'fse',
      imagelink: 'assets/img/masque8.jpg',
      infolink: 'https://supstudy.cm/fr/courses.php?id=2854',
      videolink: 'https://www.youtube.com/watch?v=7idbFikemxc'
    }
  ];

  ngOnInit(): void {
  }

}
