<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Cart</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Cart</li>
            </ul>
        </div>
    </div>
</section>

<section class="cart-area ptb-100">
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Name</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details">
                                    <img src="assets/img/products/img1.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/products-details">Medical Mask</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$20.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$20.00</span>

                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details">
                                    <img src="assets/img/products/img2.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/products-details">Antiseptics</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$25.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$25.00</span>

                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details">
                                    <img src="assets/img/products/img3.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/products-details">Medical Gloves</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$11.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$11.00</span>

                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>

                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/products-details">
                                    <img src="assets/img/products/img4.jpg" alt="item">
                                </a>
                            </td>

                            <td class="product-name">
                                <a routerLink="/products-details">Drugs</a>
                            </td>

                            <td class="product-price">
                                <span class="unit-amount">$50.00</span>
                            </td>

                            <td class="product-quantity">
                                <div class="input-counter">
                                    <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                    <input type="text" min="1" value="1">
                                    <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                </div>
                            </td>

                            <td class="product-subtotal">
                                <span class="subtotal-amount">$50.00</span>

                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <a href="#" class="optional-btn"><i class="flaticon-shopping-cart"></i> Continue Shopping</a>
                    </div>

                    <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                        <a href="#" class="default-btn"><i class="flaticon-shopping-cart"></i> Update Cart</a>
                    </div>
                </div>
            </div>

            <div class="cart-totals">
                <h3>Cart Totals</h3>

                <ul>
                    <li>Subtotal <span>$106.00</span></li>
                    <li>Shipping <span>$4.00</span></li>
                    <li>Total <span>$110.00</span></li>
                </ul>
                
                <a href="#" class="default-btn"><i class="flaticon-tick"></i> Proceed to Checkout</a>
            </div>
        </form>
    </div>
</section>