<app-header-one></app-header-one>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Foire aux questions</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
        </div>
    </div>
</section>

<section class="faq-section-two ptb-100">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-book-reader'></i><span>COVID-19 Basics</span></a></li>
                
                <li><a href="#"><i class='bx bx-command'></i><span>Prevention</span></a></li>

                <li><a href="#"><i class='bx bx-user-check'></i><span>Self Quarantine</span></a></li>

                <li><a href="#"><i class='bx bx-user-pin'></i><span>For Caregivers</span></a></li>

                <li><a href="#"><i class='bx bx-info-square'></i><span>Terms to Know</span></a></li>
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionEx" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <a data-toggle="collapse" data-parent="#accordionEx" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThree" class="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordionEx">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExTwo" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSix">
                                <a data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSix" class="collapse show" role="tabpanel" aria-labelledby="headingSix" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSeven">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSeven" class="collapse" role="tabpanel" aria-labelledby="headingSeven" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEight">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingNine">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExTwo" href="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTen" class="collapse" role="tabpanel" aria-labelledby="headingTen" data-parent="#accordionExTwo">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExThree" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEleven">
                                <a data-toggle="collapse" data-parent="#accordionExThree" href="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEleven" class="collapse show" role="tabpanel" aria-labelledby="headingEleven" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwelve">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwelve" class="collapse" role="tabpanel" aria-labelledby="headingTwelve" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingThirteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseThirteen" class="collapse" role="tabpanel" aria-labelledby="headingThirteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFourteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFourteen" class="collapse" role="tabpanel" aria-labelledby="headingFourteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingFifteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExThree" href="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseFifteen" class="collapse" role="tabpanel" aria-labelledby="headingFifteen" data-parent="#accordionExThree">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExFour" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSixteen">
                                <a data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSixteen" class="collapse show" role="tabpanel" aria-labelledby="headingSixteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingSeventeen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseSeventeen" class="collapse" role="tabpanel" aria-labelledby="headingSeventeen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingEighteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseEighteen" class="collapse" role="tabpanel" aria-labelledby="headingEighteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingNineteen">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseNineteen" class="collapse" role="tabpanel" aria-labelledby="headingNineteen" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwenty">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFour" href="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwenty" class="collapse" role="tabpanel" aria-labelledby="headingTwenty" data-parent="#accordionExFour">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="faq-accordion-content" id="accordionExFive" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyOne">
                                <a data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                    What is the source of the virus?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyOne" class="collapse show" role="tabpanel" aria-labelledby="headingTwentyOne" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyTwo">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                    How does the virus spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyTwo" class="collapse" role="tabpanel" aria-labelledby="headingTwentyTwo" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyThree">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                    Who has had COVID-19 spread the illness to others?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyThree" class="collapse" role="tabpanel" aria-labelledby="headingTwentyThree" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyFour">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                    Will warm weather stop the outbreak of COVID-19?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyFour" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFour" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
    
                        <div class="card">
                            <div class="card-header" role="tab" id="headingTwentyFive">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordionExFive" href="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                    What is community spread?
                                    <i class="flaticon-add"></i>
                                </a>
                            </div>
    
                            <div id="collapseTwentyFive" class="collapse" role="tabpanel" aria-labelledby="headingTwentyFive" data-parent="#accordionExFive">
                                <div class="card-body">
                                    <p>Coronaviruses are a large family of viruses. Some cause illness in people, and others, such as canine and feline coronaviruses, only infect animals. Rarely, animal coronaviruses that infect animals have emerged to infect people and can spread between people.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="emergency-contact-area pb-100">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="flaticon-open-book"></i> Request Appointment</a>
                        <a routerLink="/" class="default-btn"><i class="flaticon-doctor"></i> Meet The Doctor</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+44587154756">
                            <i class="flaticon-call"></i>
                            <span>Emergency Contact</span>
                            +44 587 154756
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>