<section class="blog-area ptb-100">
    <div class="container"> 

<div class="section-title">
    <span class="sub-title">Nos facultés</span>
    <h2>Préinscrivez-vous dans nos fabuleuses facultés</h2> 
     
</div>

<div class="row">
    <div class="col-lg-4 col-md-6">
        <div class="single-blog-post-item">
            <div class="post-image">
                <a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#falsh" target="_blank">
                    <img src="assets/img/masque5.jpg" alt="image">
                </a>
                <div class="date">
                    <span>Faculté</span>
                </div>
            </div>

            <div class="post-content"> 
                <h3><a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#falsh" target="_blank">Faculté des arts, lettres et sciences humaines</a></h3>
                
            </div>
            <div class="btn-box">
                <a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#falsh" class="default-btn" target="_blank"><i class="flaticon-open-book"></i> En savoir plus</a>
                <a href="https://www.youtube.com/watch?v=7idbFikemxc" class="popup-youtube optional-btn"><i
                        class="flaticon-play-button"></i> Regarder la vidéo</a>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="single-blog-post-item">
            <div class="post-image">
                <a a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#facsciences" target="_blank">
                    <img src="assets/img/masque6.jpg" alt="image">
                </a>
                <div class="date">
                    <span>Faculté</span>
                </div>
            </div>

            <div class="post-content"> 
                <h3><a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#facsciences" target="_blank">Faculté des sciences</a></h3> 
                <br> 
            </div>
            <div class="btn-box">
                <a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#facsciences" class="default-btn" target="_blank"><i class="flaticon-open-book"></i> En savoir plus</a>
                <a href="https://www.youtube.com/watch?v=7idbFikemxc" class="popup-youtube optional-btn"><i
                        class="flaticon-play-button"></i> Regarder la vidéo</a>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6">
        <div class="single-blog-post-item">
            <div class="post-image">
                <a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#fse" target="_blank">
                    <img src="assets/img/masque8.jpg" alt="image">
                </a>
                <div class="date">
                    <span>Faculté</span>
                </div>
            </div>

            <div class="post-content"> 
                <h3><a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#fse" target="_blank">Faculté des sciences de l'éducation</a></h3> 
                <br> 
            </div>
            <div class="btn-box">
                <a href="https://uy1.uninet.cm/facultes-et-grandes-ecoles/#fse" class="default-btn" target="_blank"><i class="flaticon-open-book"></i> En savoir plus</a>
                <a href="https://www.youtube.com/watch?v=7idbFikemxc" class="popup-youtube optional-btn"><i
                        class="flaticon-play-button"></i> Regarder la vidéo</a>
            </div>
        </div>
    </div>


    
</div>

</div>
</section>