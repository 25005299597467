import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-etapes',
  templateUrl: './etapes.component.html',
  styleUrls: ['./etapes.component.scss']
})
export class EtapesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
