import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {


 
 // public lien: string= 'http://api-smartpharma.destiny-innovation.com/';   http://preinscription.destiny-innovation.com/api/
  //public lien: string= 'http://localhost/Preinscription/';
  
  
  public lien: string= 'http://preinscription.destiny-innovation.com/api/';
 
 
  //public lien: string= 'https://orientation.omdes.cm/api/';
  
  
  
  constructor() { }

}

