<div class="navbar-area navbar-style-two">
    <div class="qovid-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/home-two">
                    <img src="assets/img/logo2.png" alt="logo">
                </a>

                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>            

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Four</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-five" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Five</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-six" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">One Page Style</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/" class="nav-link">About <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About One</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Two</a></li>
                            </ul>
                        </li>

                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/live-tracker" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Live Tracker</a></li>

                        <li class="nav-item"><a routerLink="/" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/doctors" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Doctors</a></li>
                                
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/prevention" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Prevention</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/appointment" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Appointment</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/spread" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Spreads</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/outbreak" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Outbreak</a></li>

                                <li class="nav-item"><a routerLink="/" class="nav-link">Shop <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products List</a></li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link">404 Error</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link">Coming Soon</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <a routerLink="/appointment" class="default-btn"><i class="flaticon-open-book"></i> Appointment</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>