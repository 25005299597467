
<div *ngIf="demostatebool" class="live-data-sidebar {{demostate}}">
    <div class="inner-content">
        <div class="post-image">
             
            <!-- <img src="assets/img/masque5.jpg" alt="image"> -->
            <img src="assets/img/demo.gif" alt="HTML5 Icon" width="300" height="300">
    </div> 
    <button (click)="fermer()" class="default-btn">Fermer</button>
        
    </div>
 
</div>


<div *ngIf="!lancerTest">
  <h3 style="color: #006b98;text-align:center">
    <b>Après mon Baccalauréat ?</b>
  </h3>
  <mat-card class="mycard1">
    <mat-card-title>
      <b>
        <span style="color: #006b98;font-size:16px">Questionnements</span>
      </b>
    </mat-card-title>
    <p>
      Que faire après le Bac ? Comment poursuivre mes études de façon cohérente après l’obtention de mon diplôme ? Autant de questions
      que vous êtes très nombreux à vous poser, et pour cause : bien s’orienter, c’est déjà une partie de votre réussite
      vers l’insertion professionnelle. L’essentiel est donc déjà d’avoir toutes les informations et tous les outils pour
      se poser les bonnes questions, et ainsi mieux avancer dans sa réflexion.
    </p>
  </mat-card>
  <br>
  <h3 style="color: #006b98;text-align:center">
    <b>Pourquoi faire un test d'orientation ?</b>
  </h3>
  <mat-card class="mycard1">
    <mat-card-title>
      <b>
        <span style="color: #006b98;font-size:16px">Importance de mon orientation en ligne</span>
      </b>
    </mat-card-title>
    <p>
      Le test d’orientation est l’un des outils de base sur lequel vous pouvez appuyer votre réflexion concernant vos études et
      votre projet professionnel. C’est un premier pas dans la maturation de votre projet et il a pour avantage de souvent
      ouvrir votre esprit vers de nouvelles perspectives, jusque-là inconnues ou ignorées. C’est aussi l’occasion de mieux
      se connaître et de révéler des aspects de votre personnalité que vous aviez peut-être sous-estimée.
    </p>
  </mat-card>
  <br>
  <h3 style="color: #006b98;text-align:center">
    <b>Bienvenue dans le test d'Inventaire d'Intérêts professionnels (IRMR)</b>
  </h3>
  <br>
  <mat-card class="mycard1">
    <mat-card-title>
      <b>
        <span style="color: #006b98;font-size:16px">Informations générales sur le test</span>
      </b>
    </mat-card-title>
    <ul>
      <li>
        <span style="color: #006b98;font-size:16px">Nom ou intitulé :</span> IRMR</li>
      <li>
        <span style="color: #006b98;font-size:16px">Signification :</span> Intérêts de Rothwell-Miller Forme Révisée
      </li>
      <li>
        <span style="color: #006b98;font-size:16px">1ère année de sortie :</span> 1993</li>
      <li>
        <span style="color: #006b98;font-size:16px">Nom de l'auteur :</span> M. Miller, et Jack W. Rothwell</li>
    </ul>
    <p>
      <b>
        <span style="color: #006b98;font-size:16px">But du test : </span>
      </b>Ce questionnaire a pour objectif de recueillir rapidement et économiquement des renseignements dans l exploration
      des intérêts, attitudes et motivations de personnes dans le cadre de projets professionnels et/ou de formations.
    </p>
    <p>
      <b>
        <span style="color: #006b98;font-size:16px">Sur le plan du fond :</span>
      </b> L’inventaire d’intérêts de Rothwell-Miller est un test d’évaluation psychologique qui explore les particularités d’un
      sujet afin de l’amener à comprendre pourquoi il réussirait mieux dans une activité professionnelle plus que dans une
      autre.
    </p>
  </mat-card>
  <mat-card class="mycard1">
    <mat-card-title>
      <span style="color: #006b98;font-size:16px">
        <b>Comment ça marche ?</b>
      </span>
    </mat-card-title>
    <ul>
      <li>
        <span style="color: #006b98;font-size:16px">Principe 1: </span> lʼIRMR3 est utilisé dans les démarches dʼorientation scolaire et professionnelle (bilans, reconversion,
        mobilité) ou de connaissance de soi et de développement personnel.</li>
      <li>
        <span style="color: #006b98;font-size:16px">Principe 2: </span> il sʼagit, pour la personne, de classer une série de 12 métiers organisés en 9
        groupes par ordre de préférence sans tenir compte de la rémunération ni de ses possibilités de réussite.</li>
      <li>
        <span style="color: #006b98;font-size:16px">Principe 3: </span> chacun des métiers représente une catégorie dʼintérêts spécifiques qui composent les 6 types
        généraux : Réaliste, Investigateur, Artistique, Social, Entreprenant, Conventionnel.</li>
    </ul>
    <p>
      <b style="color: #006b98;font-size:16px">Ce que ça évalue</b>
    </p>
    <ul>
      <li>
        <span style="color: #006b98;font-size:16px">Globalement: </span> votre type de personnalité d'Holland (RIASEC)
      </li>
      <li>
        <span style="color: #006b98;font-size:16px">Spécifiquement : </span> vos
        <span style="color: #006b98;font-size:16px">
          <b>centres d'intérêt spécifiques </b>
        </span>qui composent les 6 types généraux de personnalité d'Holland :
        <span style="color: #006b98;font-size:16px">
          <b>Réaliste, Investigateur, Artistique, Social, Entreprenant, Conventionnel</b>
        </span>.</li>
    </ul>
  </mat-card>
  <mat-card class="mycard1">
    <mat-card-title>
      <span style="color: #006b98;font-size:16px">
        <b>Comment avoir mes résultats après le test ?</b>
      </span>
    </mat-card-title>
    <ul>
      <li>
        <span style="color: #006b98;font-size:16px">Étape n°1 : </span> valider votre test en cliquant sur le bouton 'Terminer' (après avoir répondu à la dernière question
        de ce test) et confirmer la validation de votre test.</li>
      <li>
        <span style="color: #006b98;font-size:16px">Étape n°2 : </span> une fois que vous avez validé le test, instantanément vous aurez les résultats de votre test
        sur votre écran.</li>
      <li>
        <span style="color: #006b98;font-size:16px">Étape n°3 : </span> pour toutes inquiétudes, en bas de l'écran de vos résultats, à partir du bouton « Contacter un
        CO », vous avez l'option d'être directement connecté (appel, sms ou WhatsApp) à un expert du domaine pour vous expliciter
        ou vous en dire plus de vos résultats. Celui-ci apportera des réponses à vos éventuelles questions, ainsi que pour
        des recommandations à suivre.</li>
    </ul>
  </mat-card>
  <br>
  <div class="inner2">
    <button mat-button class="butonv" (click)="initGestionTest()">Commencer le test</button>
  </div>
</div>
<h2 *ngIf="!isEndTest && lancerTest" style="color: #006b98;text-align:center">
  <b>Le test d'Inventaire d'Intérêts professionnels (IRMR) est en cours...</b>
</h2>

<mat-card *ngIf="!isEndTest && lancerTest" class="mycard">
  <p style="color: #000000;text-align:center;font-size:16px;">Pour chaque groupe de métier qui vous sera présenté, veuillez les classer par ordre de préférence. Pour ce faire, sur chaque nom de métier ci-dessous,
    il vous suffit d'effectuer un
    <em>
      <b>
        <span style="color: #006b98;font-size:16px">clic glissé-déposé</span>
      </b>
    </em> de haut vers le bas ou du bas vers le haut. Ainsi de suite, le classement du haut (métier plus préféré) vers le bas (métier
    moins préféré) reflètera votre ordre de préférence de ces métiers Puis passez au prochain groupe de métier en cliquant
    sur le bouton
    <b>
      <span style="color: #006b98;font-size:16px">« Suivant »</span>
    </b>.</p>
    <div id="inner6" ><button (click)="ouvrir()" class="default-btn">Voir la démo</button></div>
    
</mat-card>
<p *ngIf="!isEndTest && lancerTest" style="color: #000000;text-align:center;font-size:17px;">
  <!--  
  <b>Groupe de métier numéro <span style="color: #7e0d09;text-align:center;font-size:20px;">{{ compteurGrpeM }}</span> sur <span style="color: #006b98;text-align:center;font-size:20px;">9</span></b></p>
  -->
  <div id="outer5" *ngIf="lancerTest && !isEndTest" style="text-align:center;margin-left: auto;margin-right: auto;">
      <div id="inner5" *ngIf="compteurGrpeM==1" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp1MetierChoix)">
        <div class="example-box" *ngFor="let metier1 of grp1MetierChoix" cdkDrag>{{metier1}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==2" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp2MetierChoix)">
        <div class="example-box" *ngFor="let metier2 of grp2MetierChoix" cdkDrag>{{metier2}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==3" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp3MetierChoix)">
        <div class="example-box" *ngFor="let metier3 of grp3MetierChoix" cdkDrag>{{metier3}}</div>
      </div> 
      <div id="inner5" *ngIf="compteurGrpeM==4" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp4MetierChoix)">
        <div class="example-box" *ngFor="let metier4 of grp4MetierChoix" cdkDrag>{{metier4}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==5" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp5MetierChoix)">
        <div class="example-box" *ngFor="let metier5 of grp5MetierChoix" cdkDrag>{{metier5}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==6" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp6MetierChoix)">
        <div class="example-box" *ngFor="let metier6 of grp6MetierChoix" cdkDrag>{{metier6}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==7" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp7MetierChoix)">
        <div class="example-box" *ngFor="let metier7 of grp7MetierChoix" cdkDrag>{{metier7}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==8" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp8MetierChoix)">
        <div class="example-box" *ngFor="let metier8 of grp8MetierChoix" cdkDrag>{{metier8}}</div>
      </div>
      <div id="inner5" *ngIf="compteurGrpeM==9" cdkDropList class="example-list" (cdkDropListDropped)="drop($event,grp9MetierChoix)">
        <div class="example-box" *ngFor="let metier9 of grp9MetierChoix" cdkDrag>{{metier9}}</div>
      </div>
    </div> 
  <div *ngIf="compteurGrpeM==9">
    <hr>
    <br>
    <h3 style="color: #006b98;text-align:center">Saisissez ici, les 3 métiers que vous préfériez entre tout qu'il soit indiqué ou non dans les groupes de métiers dont
      vous avez parcourus précédemment.
    </h3>
    <form class="example-form">
      <mat-form-field class="example-full-width">
        <mat-label>Cliquez ici et entrez au clavier votre 1er métier de préférence</mat-label>
        <input matInput placeholder="Votre choix" [formControl]="metierCle1" value="" required>
        <mat-error>Veuillez renseigner ce champ !</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field class="example-full-width">
        <mat-label>Cliquez ici et entrez au clavier votre 2ème métier de préférence</mat-label>
        <input matInput placeholder="Votre choix" [formControl]="metierCle2" value="" required>
        <mat-error>Veuillez renseigner ce champ !</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field class="example-full-width">
        <mat-label>Cliquez ici et entrez au clavier votre 3ème métier de préférence</mat-label>
        <input matInput placeholder="Votre choix" [formControl]="metierCle3" value="" required>
        <mat-error>Veuillez renseigner ce champ !</mat-error>
      </mat-form-field>
      <br>
    </form>
  </div>
  <!-- <button mat-stroked-button color="primary">Primary</button> -->
  <br>
  <div class="inner2">
    <button mat-button class="butong" (click)="goToDivP()" *ngIf="!firstDiv && !isEndTest">Précédent</button>

    <button id="myBtn" title="Groupe de métier suivant" mat-button class="butonv2" (click)="goToDivN()" *ngIf="!isEndTest && lancerTest" [disabled]="isDisabled">{{contentNext}}</button>
  </div>

  <div *ngIf="isDisabled && lancerTest && !isEndTest"><h3 style="color: #980500;text-align:center"><b>Avant de pouvoir avancer (activer le bouton suivant) Veuillez classer les métiers ci-dessus en faisant au moins 5 déplacements ou 5 drag and drop !</b></h3></div>

  <div *ngIf="lancerTest">
    <h3 style="color: #006b98;text-align:center">Votre progression globale est de : <span style="color: #009819;text-align:center"><b>{{ valueProgress }}%</b></span></h3>

    <div>
      
        <div style="position:relative; top: -60px; left: 30px;">
            
        </div>

    <mat-progress-bar mode="determinate" value="{{valueProgress}}" style="text-align:center" ></mat-progress-bar>
  </div>
  <!-- Interprétation du test-->
  <div *ngIf="isEndTest && metierCle1.value.length!=0 && metierCle2.value.length!=0 && metierCle3.value.length!=0">
    <div *ngIf="!isresulTestIRMR">
      <br>
      <h2 style="color: #006b98;text-align:center;">Merci d'avoir validé votre test d'IRMR.</h2>
      <div class="inner2">
        <button mat-button class="butonv" (click)="etalonageTestIRMR()">Voir mes résultats</button>
      </div>
      <mat-card-content *ngIf="isresulTestIRMR">
        <h3 class="card-title" style="color: #006b98;text-align:center;font-size:17px;">Résultats de votre test d'IRMR</h3>
      </mat-card-content>
    </div>



    <div *ngIf="isresulTestIRMR && verifResult">
      <div *ngIf="!isErrorResultTest">
        <h4 style="color: #006b98;text-align:center;font-size:17px;">A propos de votre personnalité : rapport d'Orientation
        </h4>
        <div *ngIf="resultClasseCat==='Réaliste'">
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Métiers que j'envisage</span>
              </b>
            </mat-card-title>
            <ol>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle1.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle2.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle3.value }}</span>
              </li>
            </ol>
            <p>Vos centres d'intérêt professionnels sont orientés vers les activités
              <span style="color: #006b98;font-size:16px">
                <b>PRATIQUES</b>
              </span>,
              <span style="color: #006b98;font-size:16px">
                <b>TECHNIQUES</b>
              </span>, et du
              <span style="color: #006b98;font-size:16px">
                <b>PLEIN AIR</b>
              </span>, ce qui vous classe dans la catégorie
              <span style="color: #006b98;font-size:16px">
                <b>RÉALISTE</b>
              </span> d'Holland.
            </p>
          </mat-card>
          <h2 style="color: #006b98;text-align:center">Quelques informations détaillées sur vos résultats</h2>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Axe principal</span>
              </b>
            </mat-card-title>
            <p>Le Réaliste est attiré par des métiers à forte composante manuelle et technique. C'est alors qui se retrouve
              dans les métiers :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Plein air :</span>intérêt pour les activités se déroulant à l’extérieur, impliquant des déplacements et une
                certaine dépense physique.</li>
              <li>
                <span style="color: #006b98;font-size:16px">Pratiques : </span>intérêt pour les travaux de construction, fabrication, réparation; préfère les activités
                manuelles aux activités intellectuelles.</li>
              <li>
                <span style="color: #006b98;font-size:16px">Techniques : </span>intérêt pour les activités impliquant l’utilisation de machines, d’instruments et de
                dispositifs techniques.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Quelques points essentiels</span>
              </b>
            </mat-card-title>
            <p>Le Réaliste est attiré par des métiers à forte composante manuelle et technique. C'est alors qui se retrouve
              dans les métiers :
            </p>
            <p>
              <span style="color: #006b98;font-size:16px">Réaliste :</span>Cette rubrique correspond au goût pour les activités concrètes, pratiques, qui nécessitent
              un travail manuel et une certaine dépense physique, comme : réparer, conduire des machines ou utiliser des
              outils. Les personnes qui ont un score élevé dans cette rubrique préfèrent l’action à la réflexion, les problèmes
              concrets aux problèmes abstraits. Il en résulte une attirance pour les métiers techniques, manuels, et le travail
              en plein air (ingénieur des eaux et forêts, militaire, conducteur routier, sportif professionnel, charpentier,
              ingénieur en électronique, mécanicien...). Les personnes « Réalistes » ont un certain sens pratique, de bonnes
              aptitudes techniques et une certaine maîtrise de leur corps.
            </p>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « PRATIQUES »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les travaux de construction, de fabrication, de réparation, et que vous préférez
              généralement les activités manuelles par rapport aux activités intellectuelles.
            </p>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">L’hôtellerie restauration et les arts ménagers : </span>
                Cuisinier, Serveur, Barman, Boulanger, Boucher, Caviste, Employé de pressing, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers de l’industrie : </span>Chaudronnier, Carrossier, Horloger, Imprimeur typographe, Magasinier,
                etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers du bâtiment : </span>Maçon, Plâtrier, Couvreur, Charpentier, Menuisier, Vitrier, Plombier,
                etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers de l’artisanat : </span>Tapissier, Serrurier, Cordonnier, Relieur, etc.
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers de déplacement : </span>Conducteur d’engins de travaux publics, Cariste, etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « TECHNIQUES »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités impliquant l'utilisation de machine, d’instruments et de dispositifs
              sophistiqués.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la mécanique et de la métallurgie : </span>
                Ingénieur en aéronautique, Ingénieur en microtechniques, Dessinateur industriel, Mécanicien garagiste, Mécanicien d’entretien,
                Outilleur, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de l’électricité et l’électronique :
                </span>Monteur câbleur, Mécanicien d’ascenseur, Électronicien, Technicien de télécommunications, Technicien
                hifi vidéo, Technicien de maintenance en électroménager, etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « DU PLEIN AIR »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités professionnelles se déroulant à l’extérieur, en contact avec la nature.
              Vous préférez les déplacements et la dépense physique aux activités régulières, routinières, sédentaires, se
              déroulant en bureau.
            </p>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la mer : </span> Officier de la marine marchande, Matelot, Plongeur Scaphandrier, Patron de
                pêche, Garde-pêche, Conchyliculteur, Pisciculteur, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de l’environnement : </span>Ingénieur des eaux et forêts, Technicien en hygiène et sécurité publique,
                Hydrogéologue, Paysagiste, Garde-chasse, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers en contact avec les animaux : </span>Éleveur, Palefrenier soigneur, Apiculteur, Maître chien,
                Toiletteur pour animaux, Gardien de zoo, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers agricoles : </span>Cultivateur, Arboriculteur, Viticulteur, Maraîcher, etc. ;
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de maintien de l’ordre : </span>militaire, gendarme, sapeur-pompier...
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers du sport : </span>Professeur d’éducation physique et sportive, Entraîneur sportif, Moniteur de
                ski, Guide de haute montagne, Maître-nageur sauveteur, etc. ;
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers du bâtiment : </span>Métreur, Laveur de vitres, etc. ;
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers du transport : </span>Conducteur routier, Conducteur de bus, Chauffeur livreur, Facteur,
                etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Filières recommandées au sein de notre Université</span>
              </b>
            </mat-card-title>
            <p> Vu les résultats de votre test, nous vous
              <em>recommandons vivement</em> de vous préinscrire dans l'une des filières ci-dessous :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Informatique </span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Mathématiques</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Physique</span>
              </li>
            </ul>
          </mat-card>
        </div>
        <div *ngIf="resultClasseCat==='Investigateur'">
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Métiers que j'envisage</span>
              </b>
            </mat-card-title>
            <ol>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle1.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle2.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle3.value }}</span>
              </li>
            </ol>
            <p>Vos centres d'intérêt professionnels sont orientés vers les activités
              <span style="color: #006b98;font-size:16px">
                <b>MÉDICALES</b>
              </span> et
              <span style="color: #006b98;font-size:16px">
                <b>SCIENTIFIQUES</b>
              </span>, ce qui vous classe dans la catégorie
              <span style="color: #006b98;font-size:16px">
                <b>INVESTIGATEUR</b>
              </span> d'Holland.
            </p>
          </mat-card>
          <h2 style="color: #006b98;text-align:center">Quelques informations détaillées sur vos résultats</h2>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Axe principal</span>
              </b>
            </mat-card-title>
            <p>L’Investigateur privilégie les activités d’observation et d’analyse, les problèmes à résoudre, et apprécie les
              environnements stimulants intellectuellement. C'est alors qui se retrouve dans les métiers :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Médicaux : </span>intérêt pour le domaine de la santé, pour les activités de soin et de soulagement des malades.
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Scientifiques : </span>intérêt pour les activités d’analyse, d’étude, d’investigation, recherche, d’expérimentation
                impliquant un travail intellectuel.</li>
              <li>
                <span style="color: #006b98;font-size:16px">Techniques : </span>intérêt pour les activités impliquant l’utilisation de machines, d’instruments et de
                dispositifs techniques.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Quelques points essentiels</span>
              </b>
            </mat-card-title>
            <p>
              <span style="color: #006b98;font-size:16px">Investigateur : </span>Cette rubrique correspond au goût pour les activités intellectuelles. Le monde des idées,
              la recherche de connaissances et les théories scientifiques. Les personnes qui ont un score élevé apprécient
              généralement résoudre des problèmes abstraits, analyser des données, et mener des études. Les métiers correspondants
              à cette rubrique sont variés : chercheur scientifique, physicien, astronome, sociologue, biologiste, généticien,
              médecin, pharmacien... Les personnes « Investigatrices » sont plutôt réservées, indépendantes, manifestent
              une grande curiosité intellectuelle et possèdent des aptitudes scientifiques.
            </p>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « MÉDICAL »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par le domaine de la santé, les activités de soin et de soulagement des malades.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les grandes spécialités médicales : </span> Médecin généraliste, Chirurgien, Cardiologue, Dermatologue, Radiologue,
                Psychiatre, Vétérinaire, Dentiste, Pharmacien, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers paramédicaux : </span>Infirmier, Aide-soignant, Employé de maternité, Infirmier en service de
                pédiatrie, Kinésithérapeute, assistant dentaire, Pédicure podologue, Opticien lunetier, Ambulancier, Manipulateur
                d’électroradiologie, Technicien de laboratoire médical, etc. </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « SCIENTIFIQUE »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités d’analyse, d’étude, d’investigation, de recherche, d’expérimentation,
              impliquant un travail intellectuel.
            </p>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la biologie et des sciences de la nature :
                </span> Ingénieur agronome, Géologue, Botaniste, Météorologue, Zoologiste, Généticien, Toxicologue, Bactériologiste,
                Pharmacologue, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la physique et de la chimie :
                </span>Astrophysicien, Ingénieur en nucléaire, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers des sciences humaines : </span>Géographe, Ethnologue, Ergonome, etc. ;
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Et d’autres : </span>Conservateur de musée scientifique, Journaliste scientifique, etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Filières recommandées au sein de notre Université</span>
              </b>
            </mat-card-title>
            <p> Vu les résultats de votre test, nous vous
              <em>recommandons vivement</em> de vous préinscrire dans l'une des filières ci-dessous :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Biologie </span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Physique</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Chimie</span>
              </li>
            </ul>
          </mat-card>
        </div>
        <div *ngIf="resultClasseCat==='Artistique'">
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Métiers que j'envisage</span>
              </b>
            </mat-card-title>
            <ol>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle1.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle2.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle3.value }}</span>
              </li>
            </ol>
            <p>Vos centres d'intérêt professionnels sont orientés vers les activités
              <span style="color: #006b98;font-size:16px">
                <b>ESTHÉTIQUES</b>
              </span>,
              <span style="color: #006b98;font-size:16px">
                <b>LITTÉRAIRES</b>
              </span>, et
              <span style="color: #006b98;font-size:16px">
                <b>MUSICALES</b>
              </span>, ce qui vous classe dans la catégorie
              <span style="color: #006b98;font-size:16px">
                <b>ARTISTIQUE</b>
              </span> d'Holland.
            </p>
          </mat-card>
          <h2 style="color: #006b98;text-align:center">Quelques informations détaillées sur vos résultats</h2>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Axe principal</span>
              </b>
            </mat-card-title>
            <p> L’Artistique est attiré par des activités favorisant l’expression de son imagination et sa créativité. C'est
              alors qui se retrouve dans les métiers :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Esthétiques : </span>intérêt pour les activités impliquant une dimension artistique appartenant au domaine
                pictural, graphique, etc.</li>
              <li>
                <span style="color: #006b98;font-size:16px">Littéraires : </span>intérêt pour les livres, pour les activités d’écriture, et de façon générale pour le
                domaine de la communication écrite.</li>
              <li>
                <span style="color: #006b98;font-size:16px">Musicaux : </span>intérêt pour les activités liées au chant et à la musique que ce soit comme compositeur,
                interprète ou critique.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Quelques points essentiels</span>
              </b>
            </mat-card-title>
            <p>Le Réaliste est attiré par des métiers à forte composante manuelle et technique. C'est alors qui se retrouve
              dans les métiers :
            </p>
            <p>
              <span style="color: #006b98;font-size:16px">Artistique : </span>Cette rubrique correspond au goût pour les arts et toutes les activités qui autorisent
              une libre expression de soi : jouer la comédie, créer, imaginer, écrire, décorer... Les personnes ayant un
              score élevé dans cette rubrique n’aiment pas la routine et les environnements contraignants (horaires fixes,
              emploi du temps rigide, hiérarchie omniprésente). Elles peuvent s’épanouir dans des lieux de travail variés
              : théâtre, musée, librairie, agence publicitaire, galerie d’art... Les métiers correspondant au type « Artistique
              » peuvent être musicien (compositeur-interprète), danseur, photographe, dessinateur, décorateur, bijoutier,
              maquilleur, journaliste, écrivain, historien, éditeur... Les personnes « Artistes » sont généralement sensibles,
              imaginatives, impulsives, anticonformistes, et indépendantes.
            </p>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « ESTHÉTIQUES »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités de création d’objets ayant une certaine beauté visuelle, avec un
              travail sur les formes, les couleurs, le mouvement.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers du spectacle : </span> Acteur, Metteur en scène, Cameraman, Danseur, Chorégraphe, Photographe,
                etc. ;
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers des arts graphiques et du design : </span>
                Peintre, Illustrateur, Dessinateur de bandes dessinées, Dessinateur publicitaire, Dessinateur en ameublement, Coloriste,
                Cartographe, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers des arts appliqués : </span> Bijoutier, Orfèvre, Encadreur d’art, Mosaïste, Vannier, Restaurateur
                de meubles, Doreur sur métaux, Sculpteur sur bois, Graveur pierre, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la mode et de l’habillement : </span>
                Esthéticien, Maquilleur, Styliste de mode, Couturier, Tailleur, Mannequin, etc. ;
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la décoration et de l’architecture : </span>
                Urbaniste, Architecte, Maquettiste, Décorateur d’intérieur, Décorateur de vitrines, etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « LITTÉRAIRES »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités de rédaction ou de lecture et de façon générale, l’étude des systèmes
              de communication orale ou écrite.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers du livre, de l’édition et du journalisme : </span>
                Éditeur, Documentaliste, Libraire, Bibliothécaire, Professeur de français, Auteur, Romancier, Poète, Scénariste, Critique
                littéraire, Journaliste, Reporter, Rédacteur en chef, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers des langues vivantes : </span> Traducteur, Interprète, Professeur de langues, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers des sciences humaines : </span> Philosophe, Généalogiste, Historien, etc. </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « MUSICAL »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités liées au chant et à la musique, que ce soit au niveau de la composition,
              de l’interprétation ou de l’audition.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">La composition ou l’interprétation : </span> compositeur, parolier, chanteur d’opéra, musicien d’orchestre...</li>
              <li>
                <span style="color: #006b98;font-size:16px">L’enseignement : </span> Professeur de musique, Maître de chœurs, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">La technique du son : </span> Ingénieur du son, Disc-jockey, Réparateur d’instruments de musique accordeur
                de pianos, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Et d’autres : </span> Producteur de disques, Directeur d’opéra, Disquaire, Critique musicale, Musico thérapeute,
                etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Filières recommandées au sein de notre Université</span>
              </b>
            </mat-card-title>
            <p> Vu les résultats de votre test, nous vous
              <em>recommandons vivement</em> de vous préinscrire dans l'une des filières ci-dessous :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Arts (du spectacle et cinématographie, ou plastiques et histoire de l’art) </span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Langues</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Géographie</span>
              </li>
            </ul>
          </mat-card>
        </div>
        <div *ngIf="resultClasseCat==='Social'">
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Métiers que j'envisage</span>
              </b>
            </mat-card-title>
            <ol>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle1.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle2.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle3.value }}</span>
              </li>
            </ol>
            <p>Vos centres d'intérêt professionnels sont orientés vers les activités
              <span style="color: #006b98;font-size:16px">
                <b>SERVICES SOCIAUX</b>
              </span>, ce qui vous classe dans la catégorie
              <span style="color: #006b98;font-size:16px">
                <b>SOCIALE</b>
              </span> d'Holland.
            </p>
          </mat-card>
          <h2 style="color: #006b98;text-align:center">Quelques informations détaillées sur vos résultats</h2>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Axe principal</span>
              </b>
            </mat-card-title>
            <p>Le Social est altruiste, sociable et accorde une grande place aux sentiments des autres. C'est alors qui se retrouve
              dans les métiers :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Social : </span>intérêt pour les activités d’enseignement, d’aide, de soutien, d’assistance, en particulier
                auprès de personnes en difficulté, ayant des problèmes, des soucis, avec le désir de comprendre celles-ci
                et de les aider.</li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Quelques points essentiels</span>
              </b>
            </mat-card-title>
            <p>Le Réaliste est attiré par des métiers à forte composante manuelle et technique. C'est alors qui se retrouve
              dans les métiers :
            </p>
            <p>
              <span style="color: #006b98;font-size:16px">Social : </span>cette rubrique correspond au goût pour les activités relationnelles, avec l’objectif d’aider,
              d’informer, de conseiller, d’apprendre aux autres. Les personnes appartenant à cette rubrique peuvent s’épanouir
              dans des structures « humanitaires » : écoles ou centres de formation, associations d’aide au tiers-monde...
              Les métiers correspondant au type « Social » sont éducateur, travailleur social, orthophoniste, psychanalyste,
              conseiller d’orientation, instituteur, animateur de club de vacances... Les personnes « Sociales » sont souvent
              coopératives, généreuses, altruistes, idéalistes et optimistes.
            </p>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « SERVICES SOCIAUX »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités de soutien, d’assistance, d’enseignement, en particulier auprès de
              personnes en difficulté (handicapés, familles défavorisées, vieillards...), avec le désir de comprendre celles-ci
              et de les aider.
            </p>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers liés à l’aide sociale et au soutien moral :
                </span> Conseiller d’orientation, Psychologue scolaire, Educateur, assistant maternel. Conseiller professionnel,
                Travailleur social, Aide familial, Conseiller conjugal, Prêtre, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de l’enseignement, de la formation et l’animation : </span> Instituteur, Professeur de lycée,
                Conseiller pédagogique, Formateur, Animateur de club de vacances, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Certains métiers paramédicaux : </span> Psychologue clinicien, Ergothérapeute, etc.</li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Filières recommandées au sein de notre Université</span>
              </b>
            </mat-card-title>
            <p> Vu les résultats de votre test, nous vous
              <em>recommandons vivement</em> de vous préinscrire dans l'une des filières ci-dessous :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Sociologie </span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Psychologie</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Anthropologie</span>
              </li>
            </ul>
          </mat-card>
        </div>
        <div *ngIf="resultClasseCat==='Entreprenant'">
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Métiers que j'envisage</span>
              </b>
            </mat-card-title>
            <ol>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle1.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle2.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle3.value }}</span>
              </li>
            </ol>
            <p>Vos centres d'intérêt professionnels sont orientés vers les activités
              <span style="color: #006b98;font-size:16px">
                <b>CONTACTS PERSONNELS</b>
              </span>, ce qui vous classe dans la catégorie
              <span style="color: #006b98;font-size:16px">
                <b>ENTREPRENANT</b>
              </span> d'Holland.
            </p>
          </mat-card>
          <h2 style="color: #006b98;text-align:center">Quelques informations détaillées sur vos résultats</h2>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Axe principal</span>
              </b>
            </mat-card-title>
            <p>L ’Entreprenant aime imposer ses idées, influencer autrui et cherche à occuper une place de leader. Il éprouve
              un fort besoin de réussite et aspire à un statut social élevé. C'est alors qui se retrouve dans les métiers
              :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Contacts personnels : </span>intérêt pour les relations sociales, les activités de prise de parole en public,
                de discussion, d’argumentation, de persuasion, impliquant une certaine assurance et une capacité à influencer
                et diriger autrui. Cette dimension correspond fréquemment à un goût pour les professions commerciales et
                le management.</li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Quelques points essentiels</span>
              </b>
            </mat-card-title>
            <p>
              <span style="color: #006b98;font-size:16px">Entreprenant :</span> cette rubrique correspond au goût du pouvoir, du leadership, des activités de persuasion
              et d’influence. Les individus qui ont un score élevé dans cette rubrique aiment décider, convaincre, diriger,
              négocier, vendre et faire de la politique. Ils sont attirés par l’argent et aspirent à un statut social élevé.
              Ceci correspond à des métiers comme : chef d’entreprise, directeur du personnel, responsable du marketing,
              représentant de commerce, agent immobilier, député... Les personnes « Entreprenantes » sont ambitieuses, énergiques
              et ascendantes.
            </p>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « CONTACTS PERSONNELS »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les relations sociales, les activités de prise de parole en public, de discussion,
              de négociation, de persuasion, impliquant une certaine assurance et une capacité à influencer et diriger autrui.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Le domaine commercial : </span> Acheteur, Chef de produit, Commerçant, Vendeur, Employé de magasin, Agent
                immobilier, Représentant, Agent d’assurances, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de direction et management d’entreprise : </span>
                Chef d’entreprise, Patron de restaurant, Consultant, Directeur du personnel, Responsable du marketing, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers du droit et de la politique : </span> Juge, Avocat, Notaire, Huissier, Commissaire-priseur,
                Sénateur, Député, Maire, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de relations publiques : </span> Animateur de télévision, Diplomate, Enquêteur d’institut de
                sondage, etc.
              </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Filières recommandées au sein de notre Université</span>
              </b>
            </mat-card-title>
            <p> Vu les résultats de votre test, nous vous
              <em>recommandons vivement</em> de vous préinscrire dans l'une des filières ci-dessous :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Tourisme </span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Sociologie</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Histoire</span>
              </li>
            </ul>
          </mat-card>
        </div>
        <div *ngIf="resultClasseCat==='Conventionnel'">
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Métiers que j'envisage</span>
              </b>
            </mat-card-title>
            <ol>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle1.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle2.value }}</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">{{ metierCle3.value }}</span>
              </li>
            </ol>
            <p>Vos centres d'intérêt professionnels sont orientés vers les activités de
              <span>
                <b style="color: #006b98;font-size:16px">CALCUL</b> et de la
              </span>
              <span>
                <b style="color: #006b98;font-size:16px">BUREAUTIQUE</b>
              </span>, ce qui vous classe dans la catégorie
              <span>
                <b style="color: #006b98;font-size:16px">CONVENTIONNEL</b>
              </span> d'Holland.
            </p>
          </mat-card>
          <h2 style="color: #006b98;text-align:center">Quelques informations détaillées sur vos résultats</h2>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Axe principal</span>
              </b>
            </mat-card-title>
            <p>Le Conventionnel, plutôt organisé, recherche les milieux stables et structurés dans lesquels il s’adapte bien.
              C'est alors qui se retrouve dans les métiers :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Calcul:</span> intérêt pour les activités impliquant la manipulation et le traitement de données chiffrées.
                Cette dimension correspond fréquemment à un goût pour la comptabilité, les mathématiques et les statistiques.
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Bureautique : </span> intérêt pour les activités impliquant F utilisation de l’outil informatique dans des
                activités de gestion, de sécurité, de précision.</li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Quelques points essentiels</span>
              </b>
            </mat-card-title>
            <p>
              <span style="color: #006b98;font-size:16px">Conventionnel :</span> Cette rubrique correspond au goût pour les activités d’organisation et de gestion, nécessitant
              une certaine minutie et le sens du détail : établir des plannings, vérifier des comptes, classer des documents...
              Les personnes appartenant au type « Conventionnel » peuvent s’épanouir dans des milieux structurés : grandes
              administrations, banques et assurances, services administratifs et comptables d’entreprises. Les métiers correspondants
              sont expert-comptable, agent des impôts, statisticien, employé de bureau, secrétaire. Les personnes « Conventionnelles
              » sont plutôt consciencieuses, persévérantes, précises et organisées.
            </p>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « CALCUL »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les activités impliquant la manipulation et le traitement de données chiffrées.
            </p>
            <br>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la comptabilité et de la finance : </span>
                Comptable, Expert-comptable, Analyste financier, Contrôleur de gestion, Commissaire aux comptes, Fiscaliste d’entreprise,
                Inspecteur des impôts, Économiste, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Les métiers de la banque, de la bourse et de l’assurance :
                </span> Directeur d’agence bancaire, Caissier de banque, Agent de change, Caissier de magasin, Cambiste,
                Rédacteur en assurances, Actuaire, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Le domaine des mathématiques et de l’informatique : </span>
                Mathématicien, Statisticien, Démographe, Ingénieur en informatique, Analyste système, Programmeur, etc. </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Centre d’intérêt pour les activités professionnelles du domaine « TRAVAIL DE BUREAU »</span>
              </b>
            </mat-card-title>
            <p>
              <b style="color: #006b98;font-size:16px">Caractéristiques générales :</b>
              <br>Si vous avez un score élevé dans ce domaine, cela signifie que vous êtes, plus que la moyenne des personnes
              de votre âge, attiré(e) par les tâches administratives, ressentant une certaine monotonie, impliquant précision
              et exactitude dans le travail.
            </p>
            <p>
              <b style="color: #006b98;font-size:16px">Voies d’orientation ou secteurs professionnels susceptibles de vous intéresser :</b>
              <br>En cas de score élevé, les secteurs professionnels susceptibles de vous intéresser peuvent être :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Le domaine administratif :</span> Directeur administratif, Commis de bureau, Secrétaire, Employé de mairie,
                Archiviste, Standardiste, Greffier, etc. ;</li>
              <li>
                <span style="color: #006b98;font-size:16px">Le domaine de la gestion commerciale : </span> Administrateur de biens, Employé de banque, Employé de bureau
                d’assurances, Employé des postes, Agent de voyages, etc. </li>
            </ul>
          </mat-card>
          <mat-card class="mycard1">
            <mat-card-title>
              <b>
                <span style="color: #006b98;font-size:16px">Filières recommandées au sein de notre Université</span>
              </b>
            </mat-card-title>
            <p> Vu les résultats de votre test, nous vous
              <em>recommandons vivement</em> de vous préinscrire dans l'une des filières ci-dessous :
            </p>
            <ul>
              <li>
                <span style="color: #006b98;font-size:16px">Psychologie </span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Informatique</span>
              </li>
              <li>
                <span style="color: #006b98;font-size:16px">Philosophe</span>
              </li>
            </ul>
          </mat-card>
        </div>
        <br>
        <p class="card-title" style="color: #000000;text-align:center;font-size:16px;"><span style="color: #000000;text-align:center;font-size:18px;"><b>Code du rapport d'orientation : </b></span>
          <span style="color: #64140f;text-align:center;font-size:25px;">
            <b>{{mycode}}</b>
          </span>
          <br>
          <span style="color: #006b98;text-align:center;font-size:16px;">
            <em>N.B : veuillez relever ce code et le garder précieusement. Ce code sera indispensable pour poursuivre votre processus
              de préinscription.</em>
          </span>
        </p>
        <br>
        <hr> 
        <p style="color: #000000;text-align:center;font-size:17px;">Pour avoir un entretien d'explicitation et des recommandations spécifiques : veuillez contacter un expert psychologue
          ou un conseiller d'orientation de l’Université de Yaoundé I via l'outil tchat en bas extrême droite de votre écran.
          <br>
          <br>
          <span style="color: #006b98;">
            <em>Rencontrer Destiny, c'est réussir !"</em>
          </span>
        </p>
        <br>
        <div class="inner2">
            <a href="{{this.lienbase}}generate/generator_duplicata_pdf.php?code={{mycode}}" target="_blank">
              <button mat-button  class="butonv"> Télécharger mes résultats</button>
            </a>
          <a routerLink="/tchat" rel="opener referrer">
            <button mat-button class="butonb">Je consulte un expert Conseiller</button>
          </a>
        </div>
      </div>
      <div *ngIf="isErrorResultTest">
        <p style="color: #006b98;">
          <span style="font-size:16px;">
            <b>Désolé</b>
          </span>, des
          <span style="color: red;">erreurs
          </span> se sont produites lorsque vous étiez en train de passer votre test ! Veuillez répondre à toutes les questions/affirmations.
          <br>
        </p>
        <br>
        <p style="text-align:center;">Veuillez recommencer votre test en cliquant sur le bouton ci-dessous.</p>
        <br>
        <button ion-button color="primary" round (click)="finTestIRMR()">
          <p text-capitalize>Recommencer</p>
        </button>

      </div>
      <div>
        <p>
          <span style="color: #006b98;text-align:center;font-size:18px;">
            <b>N.B: </b>
          </span>le test d'orientation que vous venez de passer est un test d’intérêt professionnel. Toutefois, nous vous invitons
          à passer d’autres types de tests afin d’approfondir votre bilan personnel en cliquant sur le lien suivant : </p>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <p style="color: #006b98;text-align:center;">
    <a href="https://www.destiny-innovation.com" target="_blank">Copyright © {{ _CLY }} Destiny.</a>
  </p>
  <p style="color: #fff;text-align:center;" (click)="finTest2IRMR()">
    K
  </p>