<app-header-one></app-header-one>

<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner banner-bg1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">Soyez prudents &</span>
                        <h1>Soyez informés sur <br><br>Covid-19</h1>
                        <p>Restez informés sur le coronavirus, restez chez vous et en sécurité pour vous et les autres, et maintenez toutes les mesures de barrières préconisées par les professionnels de la santé pour le vaincre !</p>

                        <div class="btn-box">
                            <a href="http://covid19.minsante.cm/" class="default-btn" target="_blank"><i class="flaticon-open-book"></i> En savoir plus</a>
                            <a href="https://www.youtube.com/watch?v=8c_UJwLq8PI" class="popup-youtube optional-btn"><i
                                    class="flaticon-play-button"></i> Regarder la vidéo</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/banner-img1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg2">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">Restez chez vous &</span>
                        <h1>Vous pouvez vous<br><br> protéger !</h1>
                        <p>Soyez et restez propres, maintenez une distance sociale, évitez toutes sortes de foules, y compris les foules amicales.
                            Protégez vous et protégez les autres, Sauvez l'humanité !</p>

                        <div class="btn-box">
                            <a href="https://www.who.int/fr" class="default-btn" target="_blank"><i class="flaticon-open-book"></i> En savoir plus</a>
                            <a href="https://www.youtube.com/watch?v=iVo5j9mZq7g" class="popup-youtube optional-btn"><i
                                    class="flaticon-play-button"></i> Regarder la vidéo</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/banner-img2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner banner-bg3">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <span class="sub-title">COVID-19</span>
                        <h1>Apprenez à mieux <br><br>prévenir le Covid-19</h1>
                        <p>Informez-vous sur les symptômes du coronavirus (Covid-19) et informez les autres, suivez les consignes de sécurité données par l'Organisation mondiale de la santé (OMS).</p>

                        <div class="btn-box">
                            <a href="https://sites.google.com/view/sourcescorona" class="default-btn" target="_blank"><i class="flaticon-open-book"></i> En savoir plus</a>
                            <a href="https://www.youtube.com/watch?v=BtN-goy9VOY" class="popup-youtube optional-btn"><i
                                    class="flaticon-play-button"></i> Regarder la vidéo</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="assets/img/banner-img3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-etapes></app-etapes>
 
 
<br>
<section class="emergency-contact-area">
    <div class="container">
        <div class="emergency-contact-inner">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="btn-box">
                        <a routerLink="/orientation" class="default-btn"><i class="flaticon-open-book"></i> Passer les tests</a>
                        <a routerLink="/tchat" class="default-btn"><i class="flaticon-doctor"></i> Consulter un conseiller en ligne</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-cta">
                        <a href="tel:+237222221320">
                            <i class="flaticon-call"></i>
                            <span>Centre d'appel</span>
                            +237 222 22 13 20
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-liste-facultes></app-liste-facultes>
<app-liste-filiere></app-liste-filiere>

<section class="blog-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Blog</span>
            <h2>Worldwide Latest News</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <span class="date"><i class="flaticon-event"></i> 15 April, 2020</span>
                        <h3><a routerLink="/blog-details">Over 80 million Americans under virtual lockdown</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="small-blog-post">
                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img1.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 14 April, 2020</span>
                                <h3><a routerLink="/blog-details">People worldwide adjust to new life amid COVID-19</a>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img2.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 13 April, 2020</span>
                                <h3><a routerLink="/blog-details">Coronavirus stimulus checks: What you need to know</a>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="single-small-blog-post">
                        <div class="d-flex align-items-center">
                            <div class="post-image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/small-img3.jpg" alt="image"></a>
                            </div>

                            <div class="post-content">
                                <span class="date"><i class="flaticon-event"></i> 12 April, 2020</span>
                                <h3><a routerLink="/blog-details">New Africa coronavirus crisis looms with internal
                                        spread</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>